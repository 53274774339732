import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { fetchConfig } from '@/store/config';
import { fetchConfigFeature } from '@/store/configFeature';
import { fetchProfile } from '@/store/profile';

import { addWebPushScript } from './helper/addWebPushScript';
import Route from './pages';
import { fetchGetPointsBalance } from './store/extract';

import './styles/sass/helpers/index.scss';
import './styles/sass/base/index.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

function App() {
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector(state => state.authReducer?.auth?.isLogged);

  useEffect(() => {
    (async () => {
      await Promise.allSettled([
        dispatch(fetchConfig()),
        dispatch(fetchConfigFeature())
      ]);
    })();
  }, [dispatch]);

  useEffect(() => {
    window.AuthfySdk?.init().then(result => {
      window.skdDna = result;
    });
  }, []);

  useEffect(() => {
    addWebPushScript();
  }, []);

  useEffect(() => {
    (async () => {
      if (isLogged) {
        await dispatch(fetchProfile());
        await dispatch(fetchGetPointsBalance());
      }
    })();
  }, [dispatch, isLogged]);

  return <Route />;
}

export default App;
