/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import axios, { type AxiosRequestConfig } from 'axios';
import DecodeJwt from 'jwt-decode';

import { createAppAsyncThunk } from '@/helper/redux';
import { generatePKCEPair } from '@/helper/string';
import type { IGetTokenApi } from '@/interface/token';
import api from '@/services/api';

import { revokeProfile } from '../profile';

interface ParamsAuth {
  cpf: string;
  headers?: AxiosRequestConfig['headers'];
}

type GetMemberRedirectResponse = {
  urlRedirect: string;
  RedirectError: string | boolean;
  SecAuthorizeFlow: string;
  BaseURL: string;
  Application: string;
};

export const fetchAuth = createAppAsyncThunk(
  'fetchAuth',
  async ({ cpf, headers }: ParamsAuth) => {
    const rawCpf = cpf.replace(/\D/g, '');
    const {
      data: { SecAuthorizeFlow, urlRedirect, BaseURL, Application }
    } = await api.get<GetMemberRedirectResponse>(
      `/stix/members/path-redirect?memberNumber=${rawCpf}`,
      {
        headers: {
          channel: 'stix-web',
          ...headers
        }
      }
    );

    const DNA = await window.skdDna.getDeviceInfo();
    const { challenge, verifier } = await generatePKCEPair();
    const config = {
      memberNumber: rawCpf,
      client_id: process.env.VITE_SEC4U_CLIENT_ID || '',
      redirect_uri: `${window.location.origin}/callbackAuth`,
      scope: 'openid',
      response_type: 'code',
      response_mode: 'query',
      code_challenge_method: 'S256',
      code_challenge: challenge,
      channel: 'stix-site',
      flow: SecAuthorizeFlow,
      device_dna: DNA,
      terminate: 'true'
    };

    const redirect = `${urlRedirect}?${new URLSearchParams(config).toString()}`;
    return {
      code_verifier: verifier,
      redirect,
      BaseURL: `${BaseURL}/${Application}`
    };
  }
);

export const fetchLogin = createAppAsyncThunk(
  'fetchLogin',
  async ({ code }: { code: string }, { getState }) => {
    const authReducer = getState().authReducer;
    const req = await axios.post<IGetTokenApi>(
      `${authReducer.BaseURLSec}/connect/token`,
      {
        client_id: process.env.VITE_SEC4U_CLIENT_ID,
        code,
        code_verifier: authReducer.infoAuth.code_verifier,
        grant_type: 'authorization_code',
        redirect_uri: `${window.location.origin}/callbackAuth`
      },
      {
        headers: {
          channel: 'stix-site',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    return req.data;
  }
);

export const revokeAuth = createAppAsyncThunk(
  'revokeAuth',
  async (_, { getState, dispatch }) => {
    const authReducer = getState().authReducer;
    const req = await axios.post<IGetTokenApi>(
      `${authReducer.BaseURLSec}/connect/revoke`,
      {
        client_id: process.env.VITE_SEC4U_CLIENT_ID,
        token_type_hint: 'access_token',
        token: authReducer.auth.access_token
      },
      {
        headers: {
          channel: 'stix-site',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    dispatch(revokeProfile());
    return req.data;
  }
);

interface AuthState {
  loading: boolean;
  BaseURLSec: string;
  infoAuth: {
    code_verifier: string;
    redirect: string;
  };
  auth: {
    access_token?: string;
    refresh_token?: string;
    id_token?: string;
    memberNumber: string;
    isLogged: boolean;
  };
  error: string | undefined;
}

const initialState = {
  loading: false,
  BaseURLSec: '',
  infoAuth: {
    code_verifier: '',
    redirect: ''
  },
  auth: {
    access_token: '',
    refresh_token: '',
    isLogged: false
  },
  error: ''
} as unknown as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAuth.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchAuth.fulfilled,
      (state, { payload: { code_verifier, BaseURL, redirect } }) => {
        state.error = '';
        state.loading = false;
        state.infoAuth = {
          code_verifier,
          redirect
        };
        state.BaseURLSec = BaseURL;
        window.location.href = redirect;
      }
    );
    builder.addCase(fetchAuth.rejected, (state, action) => {
      state.loading = false;
      state.infoAuth = initialState.infoAuth;
      state.auth = initialState.auth;
      state.error = action.error.message;
    });

    builder.addCase(fetchLogin.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.infoAuth = {} as AuthState['infoAuth'];
      const memberNumber = DecodeJwt<{ uid: string }>(
        action.payload.access_token
      );
      state.auth = {
        isLogged: true,
        memberNumber: memberNumber.uid,
        id_token: action.payload.id_token,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token
      };

      const userData =
        process.env.VITE_MODE !== 'production'
          ? `TST${memberNumber.uid}`
          : `STX${memberNumber.uid}`;

      try {
        window.webPushManagerAPI.setUserId(userData);
      } catch (e) {
        console.log('Error on webpush manager API setUserId', e);
      }
      window.location.replace(window.location.origin);
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = '';
      state.infoAuth = {} as AuthState['infoAuth'];
      state.auth = initialState.auth;
      state.error = action.error.message;
    });

    builder.addCase(revokeAuth.fulfilled, state => {
      state.loading = false;
      state.error = '';
      state.infoAuth = {} as AuthState['infoAuth'];
      state.auth = {} as AuthState['auth'];
    });
    builder.addCase(revokeAuth.rejected, state => {
      state.loading = false;
      state.error = '';
      state.infoAuth = {} as AuthState['infoAuth'];
      state.auth = {} as AuthState['auth'];
    });
  },
  reducers: {
    fetchRefreshReducer(state, { payload }: { payload: AuthState['auth'] }) {
      state.auth = payload;
    }
  }
});

export const { fetchRefreshReducer } = authSlice.actions;

export default authSlice.reducer;
