import { menuOccHML, menuOccPRD, menuTegra } from '@/mock/main-menus';

import { useAppSelector } from './redux';

export function useSubMenus() {
  const isTegra = useAppSelector(
    state => state.configFeature.configs['use-tregra']
  );
  if (isTegra) {
    return menuTegra;
  }

  if (process.env.VITE_MODE === 'develop') {
    return menuOccHML;
  }

  return menuOccPRD;
}
