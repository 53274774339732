/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

import type { Routes } from '@/interface/routes';

const Home = lazy(() => import('./Home'));
const Validation = lazy(() => import('./Validation'));
const Profile = lazy(() => import('./Profile'));
const ExtractPage = lazy(() => import('./Extract'));
const CallbackAuth = lazy(() => import('./CallbackAuth'));
const SendStix = lazy(() => import('./SendStix'));
const OrderHistory = lazy(() => import('./OrderHistory'));
const OrderHistoryDetail = lazy(() => import('./OrderHistoryDetail'));
const GenericError = lazy(() => import('./GenericError'));
const NotFound = lazy(() => import('./NotFound'));

const routes: Routes[] = [
  {
    path: '/',
    element: <Home />,
    shouldApplyWhiteBackground: true
  },
  {
    path: 'generic-error',
    element: <GenericError />,
    shouldRemoveDefaultLayout: true
  },
  {
    path: 'validation',
    element: <Validation />,
    shouldRemoveDefaultLayout: true
  },
  {
    path: 'profile',
    element: <Profile />,
    isPrivate: true
  },
  {
    path: 'extract',
    element: <ExtractPage />,
    isPrivate: true
  },
  {
    path: 'callbackAuth',
    element: <CallbackAuth />,
    shouldRemoveDefaultLayout: true
  },
  {
    path: 'envie-stix',
    element: <SendStix />,
    isPrivate: true
  },
  {
    path: 'order-history',
    element: <OrderHistory />,
    isPrivate: true
  },
  {
    path: 'order/:orderID',
    element: <OrderHistoryDetail />,
    isPrivate: true
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
