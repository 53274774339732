import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';

interface ConfigFeatureApi {
  key: string;
  value: boolean | string;
}

export interface ConfigFeature {
  multItens: boolean;
  mgm: boolean;
  affiliates: boolean;
  cripto: boolean;
  taxReceipt: boolean;
  chatBot: boolean;
  pin4digits: boolean;
  enabledVoiceOnMFA: boolean;
  enabledShellLaunchAlert: boolean;
  partnerShell: boolean;
  'tax-receipt': boolean;
  chatbot: boolean;
  enabledLiveloSodimac: boolean;
  'use-tregra': boolean;
}

export interface ConfigState {
  loading: boolean;
  configs: ConfigFeature;
  error: string | undefined;
}

export const fetchConfigFeature = createAppAsyncThunk(
  'fetchConfigFeature',
  async (_, { getState }) => {
    const cpf = getState().authReducer.auth.memberNumber;
    const res = await api.get<{ config: ConfigFeatureApi[] }>(
      `stix/config/features${cpf ? `?memberNumber=${cpf}` : ''}`
    );
    return res.data.config;
  }
);

const initialState = {
  loading: false,
  configs: {
    multItens: true,
    mgm: false,
    affiliates: false,
    cripto: false,
    taxReceipt: false,
    chatBot: false,
    pin4digits: false,
    enabledVoiceOnMFA: false,
    enabledShellLaunchAlert: false,
    partnerShell: false,
    enabledLiveloSodimac: false,
    'use-tregra': false
  },
  error: undefined
} as unknown as ConfigState;

const toggle = (config: ConfigFeatureApi[], key: keyof ConfigFeature) => {
  const configToggle = config?.find(t => t.key === key);
  return configToggle
    ? (configToggle.value as boolean)
    : (initialState.configs[key] as boolean);
};

const featureConfigSlice = createSlice({
  name: 'featureConfig',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchConfigFeature.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchConfigFeature.fulfilled,
      (state, action: PayloadAction<ConfigFeatureApi[]>) => {
        state.loading = false;
        state.error = undefined;
        state.configs = {
          ...state.configs,
          multItens: toggle(action.payload, 'multItens'),
          mgm: toggle(action.payload, 'mgm'),
          affiliates: toggle(action.payload, 'affiliates'),
          cripto: toggle(action.payload, 'cripto'),
          taxReceipt: toggle(action.payload, 'tax-receipt'),
          chatBot: toggle(action.payload, 'chatbot'),
          pin4digits: toggle(action.payload, 'pin4digits'),
          enabledVoiceOnMFA: toggle(action.payload, 'enabledVoiceOnMFA'),
          enabledShellLaunchAlert: toggle(
            action.payload,
            'enabledShellLaunchAlert'
          ),
          partnerShell: toggle(action.payload, 'partnerShell'),
          enabledLiveloSodimac: toggle(action.payload, 'enabledLiveloSodimac'),
          'use-tregra': toggle(action.payload, 'use-tregra')
        };
      }
    );
    builder.addCase(fetchConfigFeature.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default featureConfigSlice.reducer;
