import styled from 'styled-components';

export const Container = styled.div<{ $totalWidth: number }>`
  width: ${props => props.$totalWidth}px;
  height: 4px;
  background-color: #e5e5ea;
`;

export const ProgressColor = styled.div<{
  $widthColor: number;
  $totalWidth: number;
}>`
  height: 4px;
  width: ${props => props.$widthColor}px;
  background-color: #20b4fe;
`;
