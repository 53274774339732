import { useNavigate } from 'react-router-dom';

import { Buttons, LeftIcon, RigthtIcon } from './styles';

export interface LinkButtonProps extends React.HTMLProps<HTMLButtonElement> {
  label: string;
  leftIcon?: boolean;
  rightIcon?: boolean;
  $sizebutton?: 'small' | 'large';
  disabled?: boolean;
  $typeButton?: 'primary' | 'secondary';
  onClick?: () => void;
}

const LinkButton = ({
  $sizebutton,
  label,
  leftIcon,
  rightIcon,
  disabled,
  href,
  $typeButton = 'primary',
  ...props
}: LinkButtonProps) => {
  const navigate = useNavigate();
  const hrefFunction = () => {
    if (disabled || !href) {
      return;
    }
    return navigate(href);
  };
  return (
    <Buttons
      disabled={disabled}
      label={label}
      $sizebutton={$sizebutton}
      onClick={() => hrefFunction()}
      target={href}
      $typeButton={$typeButton}
      {...props}
    >
      {leftIcon && <LeftIcon />}
      {label}
      {rightIcon && <RigthtIcon />}
    </Buttons>
  );
};

export default LinkButton;
