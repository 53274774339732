import { useLayoutEffect } from 'react';

import * as S from './styles';

function Loader() {
  useLayoutEffect(() => {
    const selector = document.querySelector('body');
    if (selector?.style) {
      selector.style.paddingTop = '0px';
    }
    return () => {
      selector?.removeAttribute('paddingTop');
    };
  }, []);

  return (
    <S.Container>
      <div className="pulsating-circle"></div>
    </S.Container>
  );
}

export default Loader;
