import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100vh;

  .pulsating-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 32px;
    height: 32px;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 40px;
      background-color: ${props => props.theme.color.grey3};
      animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) -0.3s infinite;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.color.grey3};
      border-radius: 16px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      animation: pulse-dot 2s cubic-bezier(0.215, 0.61, 0.355, 1) -0.3s infinite;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
