import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IChatblipUser {
  username: string;
  password: string;
}

export interface ChatblipUserState {
  chatUser: IChatblipUser;
}

const initialState = {
  chatUser: {}
} as unknown as ChatblipUserState;

const chatblipUserSlice = createSlice({
  name: 'chatblipUser',
  initialState,
  reducers: {
    revokeChatUser(state) {
      state.chatUser = {} as IChatblipUser;
    },
    updateChatUser(state, action: PayloadAction<ChatblipUserState>) {
      state.chatUser = action.payload.chatUser;
    }
  }
});
export const { revokeChatUser, updateChatUser } = chatblipUserSlice.actions;

export default chatblipUserSlice.reducer;
