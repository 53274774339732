import { Link } from 'react-router-dom';

import { LinkButton } from '@/design-system';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { revokeAuth } from '@/store/auth';
import { updateShowInfo } from '@/store/expiredPointsConfig';

import * as S from './styles';

import './index.scss';

const userMenu = [
  {
    title: 'Meu Perfil',
    link: '/profile'
  },
  {
    title: 'Minhas Trocas',
    link: '/order-history'
  },
  {
    title: 'Histórico de Atividades',
    link: '/extract'
  },
  {
    title: 'Envie stix',
    link: '/envie-stix'
  },
  {
    title: 'Sair',
    link: '#?'
  }
];

interface IProps {
  overlay: (params?: boolean) => void;
}

const UserLogin = ({ overlay }: IProps) => {
  const profile = useAppSelector(state => state.profileReducer);
  const auth = useAppSelector(state => state.authReducer.auth);
  const pointsBalance = useAppSelector(
    state => state.extractReducer.pointsBalance
  );
  const dispatch = useAppDispatch();

  if (auth?.isLogged) {
    return (
      <div className="UserLogin">
        {profile.loading ? (
          <>
            <div
              className="skeleton"
              style={{ width: '136px', height: '40px', borderRadius: '20px' }}
            />
            <div
              className="skeleton"
              style={{ width: '136px', height: '32px', borderRadius: '20px' }}
            />
          </>
        ) : (
          <>
            <div className="UserLogin-item">
              <div
                className="user-dropdown"
                onMouseEnter={() => overlay(true)}
                onMouseLeave={() => overlay(false)}
              >
                <Link className="UserAccount" to="/#">
                  <i className="UserAccount-icon fas fa-user-circle"></i>
                  <span className="UserAccount-name">
                    Olá, {profile.profile.ContactFirstName}
                  </span>
                  <i className="UserAccount-iconArrow fas fa-chevron-down"></i>
                </Link>

                <div className="user-dropdown__sub">
                  <div className="user-dropdown__box">
                    <ul className="UserMenu">
                      {userMenu.map((item, index) => (
                        <li key={index.toString()} className="UserMenu-item">
                          <Link
                            to={item.link}
                            className="UserMenu-link"
                            onClick={async e => {
                              if (item.title === 'Sair') {
                                e.preventDefault();
                                overlay(false);
                                await dispatch(revokeAuth());
                                dispatch(updateShowInfo(false));
                              }
                            }}
                          >
                            {item.title}
                            <i className="UserMenu-icon fas fa-chevron-right"></i>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="UserLogin-item">
              <S.UserBalancerContainer>
                <S.UserBalancerText>
                  {new Intl.NumberFormat('pt-BR').format(
                    pointsBalance.Balance || 0
                  )}{' '}
                  stix
                </S.UserBalancerText>
              </S.UserBalancerContainer>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <ul className="MyAccount">
      <li className="MyAccount-item">
        <LinkButton
          label="Crie sua conta"
          className="MyAccount-link"
          href="/validation"
        />
      </li>
      <li className="MyAccount-item">
        <LinkButton
          label="Entre"
          className="MyAccount-link"
          href="/validation"
        />
      </li>
    </ul>
  );
};

export default UserLogin;
