import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import ReleaseNotes from '@/components/ReleaseNotes';
import { isEmbbed, changeUrlIfIsEmbbed } from '@/helper';

const TopBar = () => {
  const [stateClassName, setStateClassName] = useState('');
  const SCROLL_BREAKPOINT = 300;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > SCROLL_BREAKPOINT
        ? setStateClassName('is-hidden')
        : setStateClassName('');
    });
  }, []);

  document.body.classList.add('has-topBar');

  const dataTopBar = [
    {
      id: 1,
      title: ' O que é Stix?',
      path: '/sobre-a-stix'
    },
    {
      id: 2,
      title: 'Stix para empresas',
      path: '/stix-para-empresas'
    },
    {
      id: 3,
      title: 'Central de Ajuda',
      path: '/ajuda'
    }
  ];

  return (
    <section className={`TopBar ${stateClassName}`}>
      <div className="container">
        <nav className="TopBar-nav">
          <ul className="TopBar-nav__list">
            <ReleaseNotes />

            {dataTopBar.map(item => {
              return (
                <li key={item.id.toString()} className="TopBar-nav__item">
                  <NavLink
                    target={isEmbbed() ? '_top' : ''}
                    className="TopBar-nav__link"
                    to={changeUrlIfIsEmbbed(item.path)}
                  >
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default TopBar;
