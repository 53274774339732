/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import axios from 'axios';
import DecodeJwt from 'jwt-decode';

import { IGetTokenApi } from '@/interface/token';
import type { store } from '@/store';
import { fetchRefreshReducer, revokeAuth } from '@/store/auth';

import api from './api';

const fetchRefresh = async (getStore: typeof store) => {
  try {
    const authReducer = getStore.getState().authReducer;
    const {
      data: { refresh_token, access_token, id_token }
    } = await axios.post<IGetTokenApi>(
      `${authReducer.BaseURLSec}/connect/token`,
      {
        client_id: process.env.VITE_SEC4U_CLIENT_ID,
        grant_type: 'refresh_token',
        redirect_uri: `${window.location.origin}/callbackAuth`,
        refresh_token: authReducer.auth.refresh_token
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    const memberNumber = DecodeJwt<{ uid: string }>(access_token);
    const data = {
      refresh_token,
      access_token,
      id_token,
      isLogged: true,
      memberNumber: memberNumber.uid
    };
    getStore.dispatch(fetchRefreshReducer(data));
    return data;
  } catch (error) {
    await getStore.dispatch(revokeAuth());
    return Promise.reject(error);
  }
};

export default {
  setup: (getStore: typeof store) => {
    api.interceptors.request.use(async request => {
      const auth = getStore.getState().authReducer.auth;
      if (!auth?.isLogged) return request;

      request.baseURL = process.env.VITE_BASE_URL_API;
      request.headers.MemberNumber = auth.memberNumber;
      request.headers.Authorization = `Bearer ${auth.access_token}`;
      request.headers.OriginApp = 'ST';
      request.headers.channel = 'stix-site';
      request.headers['ocp-apim-subscription-Key'] =
        process.env.VITE_SUBSCRIPTION_KEY;
      return request;
    });
    let isFirstRefreshTokenRequest = true;
    let subscribers: any[] = [];

    const onAccessTokenFetched = (access_token: string) => {
      subscribers = subscribers.filter(callback => callback(access_token));
    };

    const addSubscriber = (callback: (e: string) => any) => {
      subscribers.push(callback);
    };

    api.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (
          error &&
          error.response.status === 401 &&
          originalRequest?.baseURL === process.env.VITE_BASE_URL_API
        ) {
          if (isFirstRefreshTokenRequest) {
            originalRequest._retry = true;
            try {
              isFirstRefreshTokenRequest = false;
              const token = await fetchRefresh(getStore);
              onAccessTokenFetched(token.access_token);
              originalRequest.headers.Authorization = `Bearer ${token.access_token}`;
              isFirstRefreshTokenRequest = true;
              return await axios.request(originalRequest);
            } catch (error) {
              return Promise.reject(error);
            } finally {
              isFirstRefreshTokenRequest = true;
            }
          }

          const retryOriginalRequest = await new Promise(resolve => {
            addSubscriber(token => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(api.request(originalRequest));
            });
          });
          subscribers = [];
          return await retryOriginalRequest;
        }

        return Promise.reject(error);
      }
    );
  }
};
