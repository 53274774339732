import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  margin: auto;

  h1 {
    margin: 32px 0px;
    text-decoration: underline;
  }
  .routesgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    max-width: 600px;
  }
`;
