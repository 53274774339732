import getPkce from 'oauth-pkce';

export function generatePKCEPair(): Promise<{
  verifier: string;
  challenge: string;
}> {
  return new Promise((resolve, reject) => {
    getPkce(50, (error, { verifier, challenge }) => {
      if (error) {
        reject(error);
      }
      resolve({ verifier, challenge });
    });
  });
}

export const formatToThousand = (amount: string | number) => {
  if (amount) {
    if (
      String(amount).indexOf('.') !== -1 ||
      String(amount).indexOf('R$') !== -1 ||
      String(amount).indexOf(',') !== -1
    ) {
      return String(amount);
    }
  }
  const amountFormatted = amount
    ? String(amount).replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&.')
    : '0';

  return amountFormatted;
};
