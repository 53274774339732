import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useEmbed = () => {
  const { search } = useLocation();
  const embed = useMemo(
    () => new URLSearchParams(search).get('embed'),
    [search]
  );
  return { embed };
};
