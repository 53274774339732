/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { imageUrlValidator } from '@/helper/imageUrlValidator';
import api from '@/services/api';

import './index.scss';

interface Record {
  numRecords: number;
  attributes: {
    'product.displayName': any;
    'product.primaryThumbImageURL': any;
    'product.repositoryId': any;
    'sku.repositoryId': any;
    'sku.maxActivePrice': any;
    'sku.minActivePrice': any;
  };
}

interface SearchProductResponse {
  resultsList: {
    records: Record[];
  };
}

const Search = () => {
  const [results, setResults] = useState(false);
  const [productsFounded, setProductsFounded] = useState<Record[]>([]);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutOfTheBox = (event: any) => {
      if (event.target.id === 'search-box__input') {
        return;
      }
      if (
        divRef.current &&
        event.target instanceof Node &&
        !divRef.current.contains(event.target)
      ) {
        setProductsFounded([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutOfTheBox);

    return () => {
      document.removeEventListener('mousedown', handleClickOutOfTheBox);
    };
  }, []);

  const showResults = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);

      if (event.target.value.length >= 3) {
        try {
          const req = await api.get<SearchProductResponse>(
            `/stix/search/typehead?searchExpression=${search}`
          );
          setProductsFounded(req.data?.resultsList?.records);

          setResults(true);
        } catch (error) {
          setResults(false);
        }
      } else {
        setResults(false);
      }
    },
    [search]
  );

  function handleSearchProducts() {
    navigate(`/catalog?searchBy=${search}`);
  }

  function onSearchProduct(event: KeyboardEvent) {
    if (event?.key === 'Enter') {
      handleSearchProducts();
    }
  }

  function resultClassName(event: boolean) {
    if (event) {
      return 'is-active';
    }
    return '';
  }

  return (
    <div className="Search">
      <label htmlFor="input-search" className="search-box">
        <input
          id="search-box__input"
          type="search"
          className="search-box__input"
          value={search}
          onChange={event => showResults(event)}
          placeholder="Procure por nome, marca, código..."
          onKeyDown={event => onSearchProduct(event)}
          maxLength={60}
        />
        <div className="search-box__bkg-content" />
        <button
          onClick={handleSearchProducts}
          className="search-box__submit fas fa-search"
          type="button"
        />
      </label>
      {productsFounded.length > 0 && (
        <div
          ref={divRef}
          className={`Search-result ${resultClassName(results)}`}
        >
          <ul className="products">
            {productsFounded.map((product, index) => {
              if (index < 4) {
                const image = imageUrlValidator(
                  String(product?.attributes['product.primaryThumbImageURL'])
                );

                const hasDiscount =
                  Number(product.attributes['sku.minActivePrice'] || 0) <
                  Number(product.attributes['sku.maxActivePrice'] || 0);

                const oldPrice = Number(
                  product.attributes['sku.maxActivePrice'] || 0
                ).toLocaleString('pt-Br');
                const price = Number(
                  product.attributes['sku.minActivePrice'] || 0
                ).toLocaleString('pt-Br');

                return (
                  <li key={index} className="products__item">
                    <Link
                      to={`/catalog/product/${product?.attributes['product.repositoryId']}?repositoryId=${product?.attributes['sku.repositoryId']}`}
                      className="product"
                    >
                      <div className="product__pictureContainer">
                        {image && (
                          <img
                            className="product__picture"
                            src={image}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="product__info">
                        <h3 className="product__name">
                          {product?.attributes['product.displayName']}
                        </h3>
                        {hasDiscount && (
                          <div className="product__priceOld">
                            De {oldPrice} stix por
                          </div>
                        )}
                        <div className="product__price">{price} stix</div>
                      </div>
                    </Link>
                  </li>
                );
              } else {
                return '';
              }
            })}
          </ul>
          <div className="Search-result__actions">
            <Link
              to={`/catalog?searchBy=${search}`}
              onClick={() => {
                setProductsFounded([]);
              }}
              className="link-showAllResults"
            >
              Mostrar todos os resultados{' '}
              <i className="link-showAllResults__icon fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
