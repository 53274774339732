import styled, { css } from 'styled-components';

export type ColorItemVariant = 'pink' | 'green' | 'purple' | 'blue' | 'yellow';

interface ColorItemProps {
  $variant: ColorItemVariant;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  z-index: 200;
`;

export const ColorItem = styled.div<ColorItemProps>`
  height: 4px;
  justify-content: space-between;
  width: 100%;
  ${({ $variant }) => {
    switch ($variant) {
      case 'blue':
        return css`
          background-color: ${props => props.theme.color.primary};
        `;
      case 'green':
        return css`
          background-color: ${props => props.theme.color.success};
        `;
      case 'pink':
        return css`
          background-color: ${props => props.theme.color.primaryPink};
        `;
      case 'purple':
        return css`
          background-color: ${props => props.theme.color.primaryPurple};
        `;
      case 'yellow':
        return css`
          background-color: ${props => props.theme.color.primaryYellow};
        `;
      default:
        return css``;
    }
  }}
`;
