import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: "Nunito Sans",sans-serif;
  }
  html {
    overflow-x: hidden;
  }
 
  body {
    overflow-x: hidden;
    padding-top: 164px;
    position: relative;
    color: #1b1b1c;
    font-size: 16px;
    line-height: 1.4;
    box-sizing: border-box;
    outline: 0;
    @media ${props => props.theme.devices['max-md']} {
      padding-top: 120px;
    }
  }
  main {
    position: relative;
  }
  a {
    text-decoration: none;
    color: #16074a;
    &:hover {
      text-decoration: none
    }
  }

  p, label {
    margin: 0;
  }

  button, input {
    border: none;
    background: transparent;
    transition: ease all 0.3s;
  }

  ul {
    list-style-type: none;
  }

  img {
    max-width: 100%;
    width: auto;
  }

  .slick-arrow {
    height: 40px;
    width: 40px;
    background: #636367!important;
    border-radius: 50%;
    z-index: 1!important;
    opacity: .4;
  }

  .slick-prev {
    left: 8px;

    &::before {
      content: "";
      font-family: 'Font Awesome\ 5 Pro';
    }
  }

  .slick-next {
    right: 8px;

    &::before {
      content: "";
      font-family: 'Font Awesome\ 5 Pro';
    }
  }

  button {
    transition: .2s;
    position: relative;
  }

  button:disabled,
  button[disabled]{
    background-color: #cad1d5 !important;
    cursor: not-allowed!important;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @supports selector(body:has(#card-filters-product-list[data-open])) and (not (overscroll-behavior: contain)) {
    body:has(#card-filters-product-list[data-open]) {
      overflow: hidden;
    }
  }
`;
