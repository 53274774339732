import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

export const ButtonsDownloadApp = styled.dl`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media ${props => props.theme.devices['min-lg']} {
    margin-top: unset;
    margin-bottom: unset;
  }
  & .ButtonsDownloadApp {
    &-title {
      color: ${props => props.theme.color.white};
      font-size: 16px;
      font-weight: 700;
      margin-right: 40px;
      flex-basis: 100%;
      margin-bottom: 16px;
      @media ${props => props.theme.devices['min-lg']} {
        flex-basis: unset;
        margin-bottom: unset;
      }
    }
    &-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
    &-button {
      align-items: center;
      background: ${props => props.theme.color.primary};
      border-radius: 24px;
      display: flex;
      justify-content: center;
      min-width: 112px;
      height: 48px;
      padding: 16px;
      border: solid 2px transparent;
      width: 112px;
    }
  }
`;

export const DownloadAppButton = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.tiny} ${theme.spacing.xxxs};
    background-color: ${theme.color.brand.gama.pure};
    border-radius: ${theme.borderRadius.pill};
    width: 110px;
  `}
`;
