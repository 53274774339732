import { Button } from '@/design-system';
import useCookie from '@/hooks/useCookie';

import './index.scss';

const CookieBar = () => {
  const [showCookieBar, setShowCookieBar] = useCookie('stix:lgpd');

  const message =
    'Para melhorar a sua experiência de navegação, guardamos estatísticas de visita e usamos cookies, saiba mais em nossa <strong>Política de Privacidade e Cookies</strong>.';

  return (
    <>
      {!showCookieBar && (
        <section className="CookieBar">
          <div className="container">
            <div className="CookieBar-content">
              <div
                className="CookieBar-message"
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
              <Button
                colortype="primary"
                label="Entendi"
                onClick={() => setShowCookieBar('true')}
                sizebutton="large"
                className="CookieBar-button"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CookieBar;
