import styled, { css } from 'styled-components';

import type { colortype } from './';

interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'label'> {
  $colortype?: colortype;
  $sizebutton?: 'small' | 'large';
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 48px;
  cursor: pointer;
  line-height: 1;
  outline: none;

  &:focus {
    border: 2px solid;
    ${props => props.theme.color.white};
  }

  ${({ $colortype }) => {
    switch ($colortype) {
      case 'primary':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.primary};
          &:hover {
            border: 2px solid ${props => props.theme.color.primaryDark};
          }
          &:active {
            background-color: ${props => props.theme.color.primaryDark};
          }
        `;
      case 'secundary':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.secondary};
          &:hover {
            border: 2px solid ${props => props.theme.color.primary};
          }
          &:active {
            background-color: ${props => props.theme.color.primaryDark};
          }
        `;
      case 'primary green':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.primaryGreen};
          &:hover {
            border: 2px solid #13741d;
          }
          &:active {
            background-color: #13741d;
          }
        `;
      case 'secundary green':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.secondaryGreen};
          &:hover {
            border: 2px solid ${props => props.theme.color.primaryGreen};
          }
          &:active {
            background-color: #13741d;
          }
        `;
      case 'primary pink':
        return css`
          color: ${props => props.theme.color.white};
          background-color: ${props => props.theme.color.primaryPink};
          &:hover {
            border: 2px solid #a8036d;
          }
          &:active {
            background-color: #a8036d;
          }
        `;
      case 'secundary pink':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.secondaryPink};
          &:hover {
            border: 2px solid ${props => props.theme.color.primaryPink};
          }
          &:active {
            background-color: #a8036d;
          }
        `;
      case 'primary purple':
        return css`
          color: ${props => props.theme.color.white};
          background-color: ${props => props.theme.color.primaryPurple};
          &:hover {
            border: 2px solid #631bb6;
          }
          &:active {
            background-color: #631bb6;
          }
        `;
      case 'secundary purple':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.secondaryPurple};
          &:hover {
            border: 2px solid #9341f1;
          }
          &:active {
            background-color: #631bb6;
          }
        `;
      case 'primary yellow':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.primaryYellow};
          &:hover {
            border: 2px solid #e9a900;
          }
          &:active {
            background-color: #e9a900;
          }
        `;
      case 'secundary yellow':
        return css`
          color: #333;
          background-color: ${props => props.theme.color.secondaryYellow};
          &:hover {
            border: 2px solid ${props => props.theme.color.primaryYellow};
          }
          &:active {
            background-color: #e9a900;
          }
        `;
      case 'status error':
        return css`
          color: #fff;
          background-color: ${props => props.theme.color.error};
          &:hover {
            border: 2px solid #95011c;
          }
          &:active {
            background-color: #95011c;
          }
          &:disabled {
            color: ${props => props.theme.color.grey6};
          }
        `;
      case 'disable':
        return css`
          color: ${props => props.theme.color.grey5};
          background-color: ${props => props.theme.color.grey2};
          cursor: not-allowed !important;
          border: none !important;
          &:hover {
            color: ${props => props.theme.color.grey5} !important;
            background-color: ${props => props.theme.color.grey2} !important;
          }
        `;
      default:
        return css``;
    }
  }}

  ${({ $sizebutton }) =>
    $sizebutton === 'large'
      ? css`
          min-width: 87px;
          width: auto;
          height: 48px;
          padding: 14px 16px 14px 16px;
        `
      : css`
          min-width: 80px;
          width: auto;
          height: 32px;
          padding: 6px 16px 6px 16px;
        `}
`;

export const LeftIcon = styled.div`
  width: 24px;
  height: 20px;
  margin-right: 4px;
`;
export const RigthtIcon = styled.i`
  width: 24px;
  height: 20px;
  margin-left: 4px;
`;
