import { type ReactNode, Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

import Layout from '@/components/Layout';
import { Loader } from '@/design-system';
import { changeUrlIfIsEmbbed } from '@/helper';
import { useAppSelector } from '@/hooks/redux';
import type { Routes } from '@/interface/routes';

import routesCatalog from './Catalog';
import routesCommons from './Commons';
import GenericError from './Commons/GenericError';
import Mapsite from './Commons/MapSite';
import routesIntitutional from './Institutional';

function ElementSuspense({ children }: { children: ReactNode }) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Loader />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

const routes: Routes[] = [
  ...routesIntitutional,
  ...routesCommons,
  ...routesCatalog
];

if (process.env.VITE_MODE === 'develop') {
  routes.push({
    element: <Mapsite />,
    path: 'map-site',
    shouldRemoveDefaultLayout: true
  });
}

function Router() {
  const isLogged = useAppSelector(state => state.authReducer.auth.isLogged);

  const router = createBrowserRouter(
    routes.map(
      ({
        element,
        path,
        isPrivate,
        shouldRemoveDefaultLayout,
        shouldApplyWhiteBackground
      }) => {
        if (isPrivate && !isLogged) {
          return {
            path,
            element: (
              <Navigate to={changeUrlIfIsEmbbed('/validation')} replace />
            )
          };
        }

        if (shouldRemoveDefaultLayout) {
          return {
            path,
            element: <ElementSuspense>{element}</ElementSuspense>
          };
        }
        return {
          path,
          errorElement: <GenericError shouldRevokeData />,
          element: (
            <ElementSuspense>
              <Layout shouldApplyWhiteBackground={shouldApplyWhiteBackground}>
                {element}
              </Layout>
            </ElementSuspense>
          )
        };
      }
    )
  );
  return <RouterProvider router={router} />;
}

export default Router;
