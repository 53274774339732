import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import genericErrorImage from '@/assets/img/generic-error.svg';
import logo from '@/assets/img/validation/Logo.svg';
import { Button } from '@/design-system';
import { useAppDispatch } from '@/hooks/redux';
import { revokeAuth } from '@/store/auth';
import { revokeHome } from '@/store/home';

import {
  HeaderComponent,
  GenericErrorComponent,
  MainComponent
} from './styles';

interface IGenericErrorProps {
  shouldRevokeData?: boolean;
}

export default function GenericError({
  shouldRevokeData = false
}: IGenericErrorProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function navigateToHelper() {
    navigate('/');
  }

  async function signOut() {
    await dispatch(revokeAuth());
  }

  function revokeData() {
    dispatch(revokeHome());
  }

  const [fraudParam] = useSearchParams();

  if (fraudParam.get('fraud')) {
    signOut();
  }

  useEffect(() => {
    if (shouldRevokeData) {
      revokeData();
    }
  }, [shouldRevokeData]);

  return (
    <GenericErrorComponent>
      <HeaderComponent>
        <Link to="/">
          <img src={logo} alt="Stix logo" />
        </Link>

        <Link to="/ajuda">Ajuda</Link>
      </HeaderComponent>
      <MainComponent className="error-container">
        <div>
          <img src={genericErrorImage} alt="" />

          <div>
            <header>
              <strong>Ops! Ocorreu um erro inesperado.</strong>
              <p>
                Por favor, tente novamente mais tarde, ou entre em contato com a
                gente pela{' '}
                <Link to="/ajuda" className="center-helper">
                  Central de Ajuda Stix
                </Link>
              </p>
            </header>

            <footer>
              <Button
                label="Ir para o início"
                colortype="primary"
                sizebutton="large"
                onClick={navigateToHelper}
              />
            </footer>
          </div>
        </div>
      </MainComponent>
    </GenericErrorComponent>
  );
}
