import { createSlice } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';
import { IPayloadAffiliateBrand } from '@/services/types.affiliateBrand';

export interface AffiliateBrandStateState {
  loading: boolean;
  payload: IPayloadAffiliateBrand;
  error: string | undefined;
}

export const fetchAffiliateBrand = createAppAsyncThunk(
  'affiliateBrand',
  async () => {
    const res = await api.get<IPayloadAffiliateBrand>(
      'stix/list-affiliate-brands?highestStixValue=true&offset=50'
    );
    return res.data;
  }
);

const initialState = {
  loading: false,
  payload: {},
  error: undefined
} as unknown as AffiliateBrandStateState;

const affiliateBrandSlice = createSlice({
  name: 'affiliateBrand',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAffiliateBrand.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchAffiliateBrand.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.error = undefined;
    });
    builder.addCase(fetchAffiliateBrand.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default affiliateBrandSlice.reducer;
