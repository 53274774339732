import styled, { css } from 'styled-components';

import ChevronDown from '@/assets/img/chevron-down.svg';

interface FieldSetProps {
  $disabled?: boolean;
}

export const Fieldset = styled.fieldset<FieldSetProps>`
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid rgb(199, 199, 204);
  background-color: transparent;
  position: relative;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  ${props =>
    props.$disabled &&
    css`
      background-color: ${props => props.theme.color.grey2};
      cursor: not-allowed;
      & legend {
        background: ${props => props.theme.color.white};
        padding: 0 4px;
      }
    `}

  &.error-fieldset {
    border: 2px solid rgb(176, 0, 32);
    background-color: rgb(255, 225, 231);
  }
  i {
    color: ${props => props.theme.color.grey5};
    position: absolute;
    top: 6px;
    right: 12px;
  }
  * > {
    cursor: pointer;
  }

  &::after {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('${ChevronDown}') no-repeat center;
    width: 46px;
    height: calc(100% + 9px);
    position: absolute;
    top: -9px;
    right: 0;
    z-index: 0;
    border-left: 2px solid ${props => props.theme.color.grey3};
  }
`;

export const Legend = styled.legend`
  padding: 0px 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${props => props.theme.color.grey5};
  background-color: ${props => props.theme.color.white};
  font-size: 12px;
  width: auto;
  margin: 0;
`;

export const SelectStyled = styled.select`
  width: 100%;
  height: 52px;
  margin-right: 12px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  padding-left: 10px;
  top: -10px;
  cursor: pointer;
  z-index: 1;

  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: ${props => props.theme.color.grey5};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${props => props.theme.color.grey5};
  }

  &::-ms-input-placeholder {
    color: ${props => props.theme.color.grey5};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    cursor: not-allowed;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
