import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App';
import Provider from './providers';

Sentry.init({
  dsn: process.env.VITE_SENTRY_DSN,
  environment:
    process.env.VITE_MODE === 'production' ? 'production' : 'staging',
  integrations: []
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider>
    <App />
  </Provider>
);
