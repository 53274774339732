export function addWebPushScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'rsyswpsdk';
  script.src = 'https://api.pushio.com/webpush/sdk/wpIndex_min.js';
  if (process.env.VITE_MODE === 'develop') {
    script.setAttribute(
      'wpconfig',
      '{"appserviceKey":"BDq2YP-OOB67OKyksIlvlp0m1InqDkTGzrb-iK_nidgmFddF6g3jkBAM7m4Tec4EM8xuG1ljmAUfajxp1jgibbo=","apiKey":"ABEUxWunVa7QXCUF37JgEdDKI","accountToken":"ABEgd3pX6MJH5y2tPzLOlN-Ck","appver":"0.0.0","apiHost":"https://cqp1qgp-webpush.oraclersys.com","lazy":true}'
    );
  } else if (process.env.VITE_MODE === 'production') {
    script.setAttribute(
      'wpconfig',
      '{"appserviceKey":"BBbXbmrocsHg6sGsOZAqxW5SK5JHDa6hycpcUQqnWqaUl1h1obFVdn75gL4rSrtfTx6sLD6HjVcLmzxhlUVzR1o=","apiKey":"ABEVIlUloBkH2vAEoDteBHteg","accountToken":"ABEgd3pX6MJH5y2tPzLOlN-Ck","appver":"0.0.0","apiHost":"https://cqp1qgp-webpush.oraclersys.com","lazy":true}'
    );
  }
  document.head.appendChild(script);
}
