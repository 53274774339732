import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import affiliateBrands from './affiliateBrands';
import authReducer from './auth';
import chatblipUserReducer from './chatblipUser';
import config from './config';
import configFeature from './configFeature';
import expiredPointsConfig from './expiredPointsConfig';
import extractReducer from './extract';
import homeReducer from './home';
import orderHistoryReducer from './orderHistory';
import profileReducer from './profile';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['extractReducer', 'orderHistoryReducer', 'chatblipUserReducer']
};

const rootReducer = combineReducers({
  configFeature,
  config,
  affiliateBrands,
  profileReducer,
  extractReducer,
  authReducer,
  orderHistoryReducer,
  homeReducer,
  chatblipUserReducer,
  expiredPointsConfig
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const serializableCheck = {
  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
};

export const store =
  process.env.VITE_MODE === 'develop'
    ? configureStore({
        reducer: persistedReducer,
        middleware: gDM => gDM({ serializableCheck }).concat(logger)
      })
    : configureStore({
        reducer: persistedReducer,
        middleware: gDM => gDM({ serializableCheck })
      });

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
