import * as S from './styles';

const BarColorFull = () => {
  const colorFull: S.ColorItemVariant[] = [
    'pink',
    'green',
    'purple',
    'blue',
    'yellow'
  ];

  return (
    <S.Container>
      {colorFull.map(item => (
        <S.ColorItem key={item} $variant={item as S.ColorItemVariant} />
      ))}
    </S.Container>
  );
};

export default BarColorFull;
