import { faAngleRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import { useAppDispatch } from '@/hooks/redux';
import { revokeAuth } from '@/store/auth';
import { updateShowInfo } from '@/store/expiredPointsConfig';

import * as S from './styles';

export interface IMenuDataProps {
  title: string;
  link: string;
}

interface IMenuProps {
  items: IMenuDataProps[];
  onClose: () => void;

  title?: string;
}

const Menu = ({ title, items, onClose }: IMenuProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  return (
    <S.Overlay onClick={onClose}>
      <S.Container>
        <S.CloseButton onClick={onClose}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            color={theme.color.border.secondary}
          />
        </S.CloseButton>
        {title && <S.Title>{title}</S.Title>}
        <S.MenuList>
          {items.map(item => (
            <S.MenuItem key={item.title}>
              <S.Link
                to={item.link}
                onClick={async e => {
                  if (item.title === 'Sair') {
                    e.preventDefault();
                    onClose();
                    await dispatch(revokeAuth());
                    dispatch(updateShowInfo(false));
                  }
                }}
              >
                <span>{item.title}</span>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  color={theme.color.grey4}
                  size="lg"
                />
              </S.Link>
            </S.MenuItem>
          ))}
        </S.MenuList>
      </S.Container>
    </S.Overlay>
  );
};

export default Menu;
