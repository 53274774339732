import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VITE_BASE_URL_API_NOT_LOGGED || '',
  timeout: 60000,
  headers: {
    'ocp-apim-subscription-Key': process.env.VITE_SUBSCRIPTION_KEY || '',
    originapp: 'ST'
  }
});

export default api;
