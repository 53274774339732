/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import axios from 'axios';
import remarkGfm from 'remark-gfm';

import { Modal } from '@/design-system';

function ReleaseNotes() {
  const [modalRelease, setModalRelease] = useState(false);
  const [contentModal, setContentModal] = useState('');

  useEffect(() => {
    if (process.env.VITE_MODE !== 'production') {
      import('../../../release-notes.md').then(async res => {
        const req = await axios.get(res.default);
        setContentModal(req.data);
      });
    }
  }, []);

  return process.env.VITE_MODE !== 'production' ? (
    <>
      <li
        className="TopBar-nav__item"
        style={{ cursor: 'pointer' }}
        onClick={() => setModalRelease(true)}
      >
        <p className="TopBar-nav__link">Release Notes</p>
      </li>
      <li
        className="TopBar-nav__item"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setTimeout(() => {
            throw new Error('Teste de erro em desenvolvimento - Botão topBar');
          }, 0);
          alert('Teste de erro enviado ao sentry!');
        }}
      >
        <p className="TopBar-nav__link">Teste sentry</p>
      </li>
      <Modal
        styleModal={{ maxWidth: '480px', minWidth: '480px' }}
        title="Release Notes"
        visible={modalRelease}
        setVisible={setModalRelease}
      >
        <ReactMarkdown
          components={{
            li(props) {
              const { ...rest } = props;
              return (
                <li
                  style={{ marginBottom: '16px', listStyleType: 'disc' }}
                  {...rest}
                />
              );
            },
            h3(props) {
              const { children } = props;
              return (
                <h3
                  style={{
                    marginBottom: '16px',
                    fontSize: '18px',
                    fontWeight: 700
                  }}
                >
                  {children}
                </h3>
              );
            },
            ul(props) {
              const { children } = props;
              return <ul style={{ marginLeft: '16px' }}>{children}</ul>;
            }
          }}
          remarkPlugins={[remarkGfm]}
        >
          {contentModal}
        </ReactMarkdown>
      </Modal>
    </>
  ) : null;
}

export default ReleaseNotes;
