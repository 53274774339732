import styled from 'styled-components';

export const GenericErrorComponent = styled.div`
  margin-top: -164px;

  @media ${props => props.theme.devices['max-md']} {
    margin-top: -120px;
  }
`;

export const HeaderComponent = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.color.border.secondary};
  padding: 0 24px;
  height: 124px;

  a {
    color: #fff;
  }
`;

export const MainComponent = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    max-width: 720px;
    display: flex;
    align-items: center;
    gap: 28px;

    @media ${props => props.theme.devices['max-sm']} {
      flex-wrap: wrap;
      margin: 32px 16px;
      justify-content: center;

      img {
        max-height: 300px;
      }
    }
  }

  & > div > div {
    display: flex;
    flex-direction: column;
    gap: 100px;

    header strong {
      font-size: 28px;
      line-height: 32px;
      color: ${props => props.theme.color.grey7};
    }

    header p {
      margin-top: 16px;
      font-size: 16px;
      color: ${props => props.theme.color.grey6};

      a {
        color: ${props => props.theme.color.primary};
        font-weight: bold;
      }
    }

    footer button {
      width: 100%;
    }
  }
`;
