import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import Search from '@/components/Search';

import * as S from './styles';

interface ISearchMobileProps {
  handleClose: () => void;
}

const SearchMobile = ({ handleClose }: ISearchMobileProps) => {
  const theme = useTheme();
  return (
    <S.SearchContainer>
      <S.TitleContainer>
        <S.Title>Pesquisar</S.Title>
        <S.CloseButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faXmark}
            color={theme.color.border.secondary}
            size="lg"
          />
        </S.CloseButton>
      </S.TitleContainer>
      <Search />
    </S.SearchContainer>
  );
};

export default SearchMobile;
