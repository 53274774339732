import { type ReactNode } from 'react';
import { Provider as ProviderRedux } from 'react-redux/es/exports';

import { ThemeProvider } from '@stix/stix-web-components';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '@/store';

import interceptor from './services/interceptor';
import GlobalStyles from './styles/global';

interface IProps {
  children: ReactNode;
}

interceptor.setup(store);

function Provider({ children }: IProps) {
  return (
    <ProviderRedux store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <GlobalStyles />
          {children}
        </ThemeProvider>
      </PersistGate>
    </ProviderRedux>
  );
}

export default Provider;
