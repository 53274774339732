import styled, { css } from 'styled-components';

export interface IMainProps {
  $shouldApplyWhiteBackground?: boolean;
}

export const Main = styled.main<IMainProps>`
  background-color: ${({ theme, $shouldApplyWhiteBackground }) =>
    $shouldApplyWhiteBackground
      ? theme.color.neutral.soft.light
      : theme.color.neutral.soft.tint};
`;

export const PageContainer = styled.div`
  ${({ theme }) => css`
    max-width: calc(${theme.breakpoints.maxDesktop} + 2 * ${theme.spacing.lg});
    margin: auto;
  `}
`;

export const ContentContainer = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.maxDesktop};
  margin: 0 ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.maxTablet}) {
    margin: 0 ${({ theme }) => theme.spacing.xs};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.maxMobile}) {
    margin: 0 ${props => props.theme.spacing.xxxs};
  }
`;
