import styled from 'styled-components';

interface ModalProps {
  $visible: boolean;
}

export const Background = styled.div<ModalProps>`
  background-color: ${({ $visible }) =>
    $visible ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  z-index: 9999;
  width: ${({ $visible }) => ($visible ? '100vw' : 0)};
  height: ${({ $visible }) => ($visible ? '100vh' : 0)};

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
