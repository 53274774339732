export const isEmbbed = () => window.location !== window.parent.location;

export const changeUrlIfIsEmbbed = (link: string) => {
  if (!isEmbbed()) {
    return link;
  }

  if (process.env.VITE_MODE === 'develop') {
    return `https://a7999301c1tst-store.occa.ocs.oraclecloud.com${link}`;
  }
  return `https://www.soustix.com.br${link}`;
};

export const wait = (delay: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export const handlerRedirectByApi = (link: string) => {
  const expression = link.split('::');
  const value = expression[1];

  return (
    {
      SearchExpression: `/catalog?searchBy=${value}`,
      Search: `/catalog?Nr=product.catalogId=${value}`,
      CampaignCategory: `/catalog?Nr=product.catalogId=${value}`,
      JoinStix: '/junte-stix',
      Link: value
    }[expression[0]] || ''
  );
};
