import { Heading } from '@stix/stix-web-components';
import styled, { css } from 'styled-components';

export const UserBalancerContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.brand.gama.pure};
    padding: ${theme.spacing.quarck} ${theme.spacing.xxxs};
    border-radius: ${theme.borderRadius.pill};
  `}
`;

export const UserBalancerText = styled(Heading).attrs({
  variant: 'span',
  size: 'xs'
})`
  color: ${({ theme }) => theme.color.neutral.soft.light};
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;
`;
