/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useRef,
  type Dispatch,
  type SetStateAction,
  useEffect,
  type ReactNode,
  type CSSProperties,
  useState
} from 'react';
import ReactDOM from 'react-dom';

import Backdrop from '../backdrop';
import * as S from './styles';

export interface ModalProps {
  /**
   * O que será renderizado dentro do Modal
   */
  children: ReactNode;
  /**
   * Estado booleano para verificar se o modal está aberto
   */
  visible: boolean;
  /**
   * setState para mudar o estado do modal
   */
  setVisible: Dispatch<SetStateAction<boolean>>;
  /**
   * Título do Modal
   */
  title?: string;
  /**
   * Verificação booleana para verificar se quando clicar fora do modal, o modal será fechado
   */
  closeOutsideModal?: boolean;
  /**
   * Verificar se tem botão de fechamento
   */
  hasCloseButton?: boolean;
  /**
   * Estilização inline do Modal
   */
  styleModal?: CSSProperties;
}

const portalRoot = document.getElementById('modal-root');

function Modal({
  title,
  visible,
  setVisible,
  closeOutsideModal = true,
  hasCloseButton = true,
  styleModal,
  children
}: ModalProps) {
  const [containerToLoad, setContainerToLoad] = useState<HTMLElement | null>(
    null
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    setContainerToLoad(document.getElementById('modal-root')!);
  }, []);

  useEffect(() => {
    if (!visible) return;

    function listener(evt: any) {
      if (evt.target !== backgroundRef.current) return;
      closeOutsideModal && setVisible(oldState => !oldState);
    }

    function listenerKey(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeOutsideModal && setVisible(oldState => !oldState);
      }
    }

    window.addEventListener('keydown', listenerKey);
    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
      window.removeEventListener('keydown', listenerKey);
    };
  }, [visible, closeOutsideModal, setVisible]);

  return (
    containerToLoad &&
    ReactDOM.createPortal(
      <Backdrop ref={backgroundRef} visible={visible}>
        <S.Content $visible={visible} style={styleModal} ref={contentRef}>
          {visible ? (
            <>
              <S.HeaderRow>
                <h3>{title}</h3>
                {hasCloseButton ? (
                  <S.IconClose
                    onClick={() => setVisible(oldState => !oldState)}
                  />
                ) : null}
              </S.HeaderRow>
              {children}
              <S.ScrollDisabler />
            </>
          ) : null}
        </S.Content>
      </Backdrop>,
      portalRoot as HTMLElement
    )
  );
}

Modal.defaultProps = {
  visible: false,
  closeOutsideModal: true,
  hasCloseButton: true
};

export default Modal;
