import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Face from '@/assets/img/footer/facebook.png';
import AppleStore from '@/assets/img/footer/icon-app-store.png';
import GooglePlay from '@/assets/img/footer/icon-google-play.png';
import Instagram from '@/assets/img/footer/instagram.png';
import LinkedIn from '@/assets/img/footer/linkedin.png';
import AppleStoreReverse from '@/assets/img/icon-app-store-reverse.svg';
import GooglePlayReverse from '@/assets/img/icon-google-play-reverse.svg';
import Copyright from '@/components/Copyright';
import Logo from '@/components/Logo';
import { changeUrlIfIsEmbbed, isEmbbed } from '@/helper';

import * as S from './styles';

import './index.scss';

const downloadLinks = [
  {
    logo: GooglePlay,
    logo2: GooglePlayReverse,
    name: 'GooglePlay',
    title: 'Disponível no Google Play',
    link: 'https://play.google.com/store/apps/details?id=br.com.soustix.app'
  },
  {
    logo: AppleStore,
    logo2: AppleStoreReverse,
    name: 'AppleStore',
    title: 'Baixar na App Store',
    link: 'https://apps.apple.com/br/app/stix/id1528851440'
  }
];

function Footer() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = 'https://s3.amazonaws.com/raichu-beta/selos/bundle.js';
    script.async = true;
    script.setAttribute('id', 'ra-embed-reputation');
    script.setAttribute('data-id', 'MUpkLTYwTEhQdlhMV3RXaDpzdGl4');
    script.setAttribute('data-target', 'reputation-ra');
    script.setAttribute('data-model', '2');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="MyFooter">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Logo type="inverse" />
            </div>
            <section className="col-md-4">
              <div className="MyFooter-store">
                <nav className="MyFooter-nav">
                  <ul className="MyFooter-navList">
                    <li>
                      <Link
                        to={changeUrlIfIsEmbbed('/regulamento-stix')}
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        Regulamento Stix
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={changeUrlIfIsEmbbed('/aviso-de-privacidade')}
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        Aviso de Privacidade
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://soustix.gupy.io/"
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        Trabalhe Conosco
                      </a>
                    </li>
                  </ul>
                  <ul className="MyFooter-navList">
                    <li>
                      <Link
                        to={changeUrlIfIsEmbbed('/sobre-a-stix')}
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        Sobre a Stix
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={changeUrlIfIsEmbbed('/ajuda')}
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        Ajuda
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={changeUrlIfIsEmbbed('/pagstix')}
                        target={isEmbbed() ? '_top' : ''}
                        className="MyFooter-navListLink"
                      >
                        PagStix
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
            <section className="col-md-3">
              <div className="MyFooter-networks">
                <p className="MyFooter-networksTitle">Nossas Redes</p>

                <ul className="MyFooter-networksList">
                  <li>
                    <Link
                      to="https://www.facebook.com/juntestix"
                      target="_blank"
                      className="MyFooter-networksListLink"
                    >
                      <img src={Face} alt="Facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/juntestix"
                      target="_blank"
                      className="MyFooter-networksListLink"
                    >
                      <img src={Instagram} alt="Instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/soustix"
                      target="_blank"
                      className="MyFooter-networksListLink"
                    >
                      <img src={LinkedIn} alt="Linkedin" />
                    </Link>
                  </li>
                </ul>
              </div>
            </section>
            <section className="col-md-2">
              <div id="reputation-ra" />
            </section>
          </div>
        </div>
      </div>
      <div className="MyFooter">
        <div className="container">
          <div className="row">
            <section className="col-md-8">
              <S.ButtonsDownloadApp className="ButtonsDownloadApp">
                <dt className="ButtonsDownloadApp-title">Baixe Nosso App</dt>
                {downloadLinks.map(item => {
                  return (
                    <dd key={item.link} className="ButtonsDownloadApp-item">
                      <S.DownloadAppButton
                        to={item.link}
                        rel="nofollow noreferrer"
                        target="_blank"
                        title={item.title}
                      >
                        <img src={item.logo2} alt={item.name} />
                      </S.DownloadAppButton>
                    </dd>
                  );
                })}
              </S.ButtonsDownloadApp>
            </section>
            <section className="col-md-4 ">
              <Copyright />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
