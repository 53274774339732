import { Link as BaseLink } from 'react-router-dom';

import styled, { keyframes } from 'styled-components';

const containerAnimation = keyframes`
0% {
  transform: translateX(-110%);
}
99% {
  transform: translateX(0);
}
`;

const overlayAnimation = keyframes`
0% {
  background: none;
}
99% {
  background: rgba(0, 0, 0, 0.5);
}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  animation: ${overlayAnimation} 0.3s ease;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 295px;
  height: 100vh;
  background-color: ${props => props.theme.color.white};
  padding: 24px 16px;
  animation: ${containerAnimation} 0.3s ease;
`;

export const CloseButton = styled.button`
  background-color: ${props => props.theme.color.primary};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Title = styled.span`
  display: block;
  font-size: 20px;
  line-height: 1.3;
  color: ${props => props.theme.color.grey5};
  padding: 8px 0;
  margin-bottom: 16px;
`;

export const MenuList = styled.ul``;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.color.grey3};
`;

export const Link = styled(BaseLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  & > span {
    font-size: 18px;
    line-height: 1.3;
    color: ${props => props.theme.color.grey8};
    transition: ease all 0.3s;
  }

  &:hover {
    & > span {
      color: ${props => props.theme.color.primaryPink};
    }
  }
`;
