import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  width: 100%;
  background: red;
  align-items: stretch;
`;

interface ITab {
  $selected: boolean;
}

export const TabItem = styled.div<ITab>`
  font-size: 18px;
  font-style: normal;
  font-weight: ${props => (props.$selected ? 700 : 400)};
  line-height: 24px;
  background-color: white;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: ${props => (props.$selected ? '#20B4FE' : '#E5E5EA')};
  border-bottom: 4px solid ${props => (props.$selected ? '#20B4FE' : '#E5E5EA')};
`;

export const TabContent = styled.div<ITab>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 10;
    }
  }
  overflow: hidden;
  opacity: ${props => (props.$selected ? '1' : '0')};
  visibility: ${props => (props.$selected ? 'visible' : 'hidden')};
  width: ${props => (props.$selected ? 'auto' : '0')};
  height: ${props => (props.$selected ? 'auto' : '0')};
  animation: ${props => (props.$selected ? 'fadeIn 1s' : 'fadeOut 1s')};
  -webkit-animation: ${props => (props.$selected ? 'fadeIn 1s' : 'fadeOut 1s')};
  -moz-animation: ${props => (props.$selected ? 'fadeIn 1s' : 'fadeOut 1s')};
  -o-animation: ${props => (props.$selected ? 'fadeIn 1s' : 'fadeOut 1s')};
  -ms-animation: ${props => (props.$selected ? 'fadeIn 1s' : 'fadeOut 1s')};
`;
