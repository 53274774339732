import { Link } from 'react-router-dom';

import LogoInverse from '@/assets/img/logo-inverse.svg';
import LogoImage from '@/assets/img/logo-light.png';
import { changeUrlIfIsEmbbed, isEmbbed } from '@/helper';

interface IProps {
  type?: 'inverse';
}
const Logo = ({ type }: IProps) => {
  return (
    <div className="Logo">
      <Link
        target={isEmbbed() ? '_top' : ''}
        to={changeUrlIfIsEmbbed('/')}
        className="Logo-link"
        title="stix"
      >
        <img
          src={type === 'inverse' ? LogoInverse : LogoImage}
          className="Logo-image"
          alt="stix"
        />
      </Link>
    </div>
  );
};

export default Logo;
