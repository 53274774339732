import { Link } from 'react-router-dom';

import { changeUrlIfIsEmbbed, isEmbbed } from '@/helper';
import { useSubMenus } from '@/hooks/useSubMenus';
import { menuItems } from '@/mock/menuItem';

interface IProps {
  overlay: (params?: boolean) => void;
}

const MainMenu = ({ overlay }: IProps) => {
  const menus = useSubMenus();

  return (
    <nav className="MainMenu">
      <ul className="level-1">
        {menus.map(item => (
          <li
            key={item.href}
            className={`level-1__item  ${
              item.level2Group?.length ? 'has-children' : ''
            }`}
            onMouseEnter={() => overlay(true)}
            onMouseLeave={() => overlay(false)}
          >
            <Link
              target={isEmbbed() ? '_top' : ''}
              to={changeUrlIfIsEmbbed(item.href)}
              className="level-1__link"
            >
              {item.level1Title}
            </Link>
            <div className="level-2-wrapper">
              <ul className="level-2">
                {item.level2Group.map(item2 => (
                  <li
                    key={item2.href}
                    className={`level-2__item  ${
                      item2.level3Group?.length ? 'has-children' : ''
                    }`}
                  >
                    <Link
                      target={isEmbbed() ? '_top' : ''}
                      to={changeUrlIfIsEmbbed(item2.href)}
                      className="level-2__link"
                    >
                      {item2.title}
                      {item2.level3Group.length ? (
                        <i className="fas fa-chevron-right" />
                      ) : null}
                    </Link>
                    <div className="level-3">
                      <ul className="level-3__list">
                        {item2.level3Group?.map(item3 => (
                          <li
                            key={item3.href}
                            className={`level-3__item  ${
                              item3.level4Group?.length ? 'has-children' : ''
                            }`}
                          >
                            <Link
                              target={isEmbbed() ? '_top' : ''}
                              to={changeUrlIfIsEmbbed(item3.href)}
                              className="level-3__link"
                            >
                              {item3.title}
                              {item3.level4Group.length ? (
                                <i className="fas fa-chevron-right"></i>
                              ) : null}
                            </Link>
                            <div className="level-4">
                              <Link
                                target={isEmbbed() ? '_top' : ''}
                                to={changeUrlIfIsEmbbed(item3.href)}
                              >
                                <h3 className="level-4__title">
                                  {item3.title}
                                </h3>
                              </Link>
                              <ul className="level-4__list">
                                {item3.level4Group.map(item4 => (
                                  <li
                                    key={item4.href}
                                    className="level-4__item"
                                  >
                                    <Link
                                      target={isEmbbed() ? '_top' : ''}
                                      to={changeUrlIfIsEmbbed(item4.href)}
                                      className="level-4__link"
                                    >
                                      {item4.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
        {menuItems.map(item => (
          <li className="level-1__item" key={item.href}>
            <Link
              to={changeUrlIfIsEmbbed(item.href)}
              target={isEmbbed() ? '_top' : ''}
              className="level-1__link"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default MainMenu;
