import { Heading } from '@stix/stix-web-components';
import styled from 'styled-components';

export const Header = styled.header`
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100vw;
  background: ${props => props.theme.color.white};
  overflow: hidden;
  display: none;
  @media ${props => props.theme.devices['max-md']} {
    display: flex;
  }
`;

export const LogoStixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: 8px;
  height: 48px;
`;

export const LogoContainer = styled.div`
  width: 92px;
`;

export const UserBalanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.brand.gama.pure};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  height: ${({ theme }) => theme.size.xs};
  padding: 0 ${({ theme }) => theme.spacing.xxxs};
`;

export const UserBalanceValue = styled(Heading).attrs({
  variant: 'span',
  size: 'xs'
})`
  color: ${props => props.theme.color.neutral.soft.light};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 0 8px;
  @media ${props => props.theme.devices['min-sm']} {
    padding: 0 16px;
  }
`;

export const Button = styled.button`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0%;
  color: ${props => props.theme.color.border.secondary};
  transition: ease all 0.3s;

  &:hover {
    color: ${props => props.theme.color.primaryPink};
  }

  & > i {
    font-size: 24px;
    padding: 4px;
  }

  & > span {
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
  }
`;
