import { createSlice } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';
import type {
  ITransaction,
  IProvision,
  IPointsToExpire,
  IPointsBalance,
  IPointsExpire
} from '@/services/types.extract';
import { IPagination } from '@/services/types.pagination';

interface ITransactionPagination extends IPagination {
  items: ITransaction[];
}

interface IProvisionPagination extends IPagination {
  items: IProvision[];
}

export const fetchActivities = createAppAsyncThunk(
  'fetchActivities',
  async (_, { getState }): Promise<ITransactionPagination> => {
    const cpf = getState().authReducer.auth.memberNumber;
    const oldActivities = getState().extractReducer.activities;

    if (oldActivities.hasMore) {
      const req = await api.get<ITransactionPagination>(
        `/stix/members/${cpf}/activities?offset=${
          oldActivities.offset + 12
        }&limit=12`
      );
      return {
        ...req.data,
        items: [...oldActivities.items, ...req.data.items]
      };
    }
    return oldActivities;
  }
);

export const fetchGetProvisions = createAppAsyncThunk(
  'fetchGetProvisions',
  async (_, { getState }): Promise<IProvisionPagination> => {
    const cpf = getState().authReducer.auth.memberNumber;
    const oldProvisions = getState().extractReducer.provisions;

    if (oldProvisions.hasMore) {
      const req = await api.get<IProvisionPagination>(
        `/stix/provisions?MemberNumber=${cpf}&offset=${
          oldProvisions.offset + 12
        }&limit=12`
      );
      return {
        ...req.data,
        items: [...oldProvisions.items, ...req.data.items]
      };
    }
    return oldProvisions;
  }
);

export const fetchGetPointsToExpire = createAppAsyncThunk(
  'fetchGetPointsToExpire',
  async (_, { getState }) => {
    const cpf = getState().authReducer.auth.memberNumber;
    const req = await api.get(`/stix/members/${cpf}/getPointsToExpire`);
    return req.data;
  }
);

export const fetchGetPointsExpire = createAppAsyncThunk(
  'fetchGetPointsExpire',
  async (_, { getState }) => {
    const cpf = getState().authReducer.auth.memberNumber;
    const req = await api.get(`/stix/members/${cpf}/getPointsExpired`);
    return req.data;
  }
);

export const fetchGetPointsBalance = createAppAsyncThunk(
  'fetchGetPointsBalance',
  async (_, { getState }) => {
    const cpf = getState().authReducer.auth.memberNumber;
    const req = await api.get(`/stix/members/${cpf}/pointBalances`);
    return req.data.items[0];
  }
);

const initialState = {
  loadingActivites: true,
  loadingMoreActivies: false,

  loadingProvisions: true,
  loadingMoreProvisions: false,

  loadingPointsToExpire: false,
  loadingPointsBalance: false,
  loadingPointsExpire: false,
  errorActivites: '' as string | undefined,
  errorProvisions: '' as string | undefined,
  errorPointsToExpire: '' as string | undefined,
  errorPointsExpire: '' as string | undefined,
  activities: {
    offset: -12,
    hasMore: true,
    items: []
  } as unknown as ITransactionPagination,
  provisions: {
    offset: -12,
    hasMore: true,
    items: []
  } as unknown as IProvisionPagination,
  pointsToExpired: [] as IPointsToExpire[],
  pointsBalance: {} as IPointsBalance,
  pointsExpire: {} as IPointsExpire[]
};

const extractSlice = createSlice({
  name: 'extract',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchActivities.pending, state => {
      if (state.activities.offset < 0) {
        state.loadingActivites = true;
      } else {
        state.loadingMoreActivies = true;
      }
    });
    builder.addCase(fetchActivities.fulfilled, (state, action) => {
      state.loadingActivites = false;
      state.loadingMoreActivies = false;
      state.activities = action.payload;
      state.errorActivites = '';
    });
    builder.addCase(fetchActivities.rejected, (state, action) => {
      state.loadingActivites = false;
      state.activities = {} as ITransactionPagination;
      state.errorActivites = action.error.message;
    });

    builder.addCase(fetchGetProvisions.pending, state => {
      if (state.provisions.offset < 0) {
        state.loadingProvisions = true;
      } else {
        state.loadingMoreProvisions = true;
      }
    });
    builder.addCase(fetchGetProvisions.fulfilled, (state, action) => {
      state.loadingProvisions = false;
      state.loadingMoreProvisions = false;
      state.provisions = action.payload;
      state.errorProvisions = '';
    });
    builder.addCase(fetchGetProvisions.rejected, (state, action) => {
      state.loadingProvisions = false;
      state.provisions = {} as IProvisionPagination;
      state.errorProvisions = action.error.message;
    });

    builder.addCase(fetchGetPointsToExpire.pending, state => {
      state.loadingPointsToExpire = true;
    });
    builder.addCase(fetchGetPointsToExpire.fulfilled, (state, action) => {
      state.loadingPointsToExpire = false;
      state.pointsToExpired = action.payload;
      state.errorPointsToExpire = '';
    });
    builder.addCase(fetchGetPointsToExpire.rejected, (state, action) => {
      state.loadingPointsToExpire = false;
      state.pointsToExpired = [];
      state.errorPointsToExpire = action.error.message;
    });

    builder.addCase(fetchGetPointsBalance.pending, state => {
      state.loadingPointsToExpire = true;
    });
    builder.addCase(fetchGetPointsBalance.fulfilled, (state, action) => {
      state.loadingPointsToExpire = false;
      state.pointsBalance = action.payload;
      state.errorPointsToExpire = '';
    });
    builder.addCase(fetchGetPointsBalance.rejected, (state, action) => {
      state.loadingPointsToExpire = false;
      state.pointsBalance = {} as IPointsBalance;
      state.errorPointsToExpire = action.error.message;
    });

    builder.addCase(fetchGetPointsExpire.pending, state => {
      state.loadingPointsExpire = true;
    });
    builder.addCase(fetchGetPointsExpire.fulfilled, (state, action) => {
      state.loadingPointsExpire = false;
      state.pointsExpire = action.payload;
      state.errorPointsExpire = '';
    });
    builder.addCase(fetchGetPointsExpire.rejected, (state, action) => {
      state.loadingPointsExpire = false;
      state.pointsExpire = [] as IPointsExpire[];
      state.errorPointsExpire = action.error.message;
    });
  },
  reducers: {}
});

export default extractSlice.reducer;
