import { createSlice } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';

export interface IRNIncidentData {
  loggedInLoggedOut: {
    loggedIn: number;
    loggedOut: number;
  };
  originApp: {
    ST: number;
    PA: number;
    EX: number;
    RA: number;
    DR: number;
  };
  subChannel: {
    stixApp: number;
    partnerApp: number;
    site: number;
    whatsApp: number;
  };
}

type IResult = {
  key?: string;
  name: string;
  value: boolean | string | string[] | IRNIncidentData;
};

interface IConfigApi {
  businessRules: IResult[];
  urls: IResult[];
  socialNetworks: IResult[];
  appStores: IResult[];
  appStoresLatestVersion: IResult[];
  partners_urls: IResult[];
  partnersGooglePlay: IResult[];
  partnersAppStore: IResult[];
  businessMessages: {
    type: string;
    title: string;
    message?: string;
    subtitle?: string;
  }[];
  credentials: {
    chatbot: {
      chatBotKey: string;
      chatBotUrl: string;
      chatBotCheckbox: boolean;
    };
    sec4u: string;
  };
  appConfiguration: IResult[];
  regulations: IResult[];
}

export const fetchConfig = createAppAsyncThunk('fetchConfig', async () => {
  const res = await api.get<IConfigApi>('stix/config');
  return res.data;
});

const initialState = {
  loading: false,
  configs: {} as IConfigApi,
  error: '' as string | undefined
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchConfig.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.configs = action.payload;
    });
    builder.addCase(fetchConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default ConfigSlice.reducer;
