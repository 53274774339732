import styled, { css } from 'styled-components';

import { LinkButtonProps } from '.';

export const Buttons = styled.button<LinkButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 48px;
  cursor: pointer;
  line-height: 1;
  outline: none;
  height: auto;
  color: #333;
  background-color: transparent;

  ${({ $typeButton }) => {
    if ($typeButton) {
      return {
        primary: css`
          &:hover {
            background-color: ${props => props.theme.color.secondary};
          }
          &:active {
            background-color: ${props => props.theme.color.primary};
          }
        `,
        secondary: css`
          background-color: ${props => props.theme.color.primary};
          justify-content: space-around;
          padding: 16px;
          color: ${props => props.theme.color.border.secondary};
          height: auto !important;
          &:hover {
            background-color: #0d98dd;
            a {
              color: #1b1b1b;
            }
          }
        `
      }[$typeButton];
    }
  }}

  &:focus {
    border: 2px solid #333;
  }

  ${({ disabled }) =>
    disabled === true
      ? css`
          color: ${props => props.theme.color.grey5};
          background-color: ${props => props.theme.color.grey2};
          cursor: not-allowed;
          &:hover {
            color: ${props => props.theme.color.grey5} !important;
            background-color: ${props => props.theme.color.grey2} !important;
          }
        `
      : null}

  ${({ $sizebutton }) =>
    $sizebutton === 'large'
      ? css`
          min-width: 87px;
          width: auto;
          height: 48px;
        `
      : css`
          min-width: 80px;
          width: auto;
          height: 32px;
        `}
`;

export const LeftIcon = styled.div`
  margin-right: 4px;
`;

export const RigthtIcon = styled.i.attrs(() => ({
  className: 'fas fa-chevron-right'
}))``;
