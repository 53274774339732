import { type ReactNode } from 'react';

import * as S from './styles';

export type colortype =
  | 'primary'
  | 'secundary'
  | 'primary green'
  | 'secundary green'
  | 'primary pink'
  | 'secundary pink'
  | 'primary purple'
  | 'secundary purple'
  | 'primary yellow'
  | 'secundary yellow'
  | 'status error'
  | 'disable'
  | 'primary blue'
  | 'blue light';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'label'> {
  /**
   * Cor do botão baseado no tema
   */
  colortype?: colortype;

  /**
   * Tamanho do botão
   */
  sizebutton?: 'small' | 'large';

  /**
   * O que será renderizado dentro do botão, pode ser uma string ou um ReactNode(Um ícone ou qualquer elemento dom)
   */
  label: ReactNode;

  /**
   * Ícone que será renderizado a esquerda
   */
  leftIcon?: ReactNode;

  /**
   * Ícone que será renderizado a direita
   */
  rightIcon?: ReactNode;

  /**
   * O que será feito depois do click
   */
  onClick?: () => void;
}

const Button = ({
  colortype,
  label,
  leftIcon,
  rightIcon,
  sizebutton,
  disabled,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <S.Button
      $colortype={colortype}
      $sizebutton={sizebutton}
      onClick={() => {
        if (disabled) return;
        onClick?.();
      }}
      {...props}
    >
      {leftIcon && <S.LeftIcon>{leftIcon}</S.LeftIcon>}
      {label}
      {rightIcon && <S.RigthtIcon>{rightIcon}</S.RigthtIcon>}
    </S.Button>
  );
};

Button.defaultProps = {
  colortype: 'primary',
  sizebutton: 'small'
};

export default Button;
