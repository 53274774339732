import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IExpiredPointsConfigState {
  showModal: boolean;
  showInfo: boolean;
}

const initialState: IExpiredPointsConfigState = {
  showModal: true,
  showInfo: false
};

const expiredPointsConfig = createSlice({
  name: 'expiredPointsConfig',
  initialState,
  reducers: {
    updateShowModal(state, action: PayloadAction<boolean>) {
      state.showModal = action.payload;
    },

    revokeExpiredPointsConfig(state) {
      state.showInfo = initialState.showInfo;
      state.showModal = initialState.showModal;
    },

    updateShowInfo(state, action: PayloadAction<boolean>) {
      state.showInfo = action.payload;
    }
  }
});
export const { updateShowModal, updateShowInfo, revokeExpiredPointsConfig } =
  expiredPointsConfig.actions;

export default expiredPointsConfig.reducer;
