import { useState } from 'react';
import { useNavigate } from 'react-router';

import BarColorFull from '@/components/BarColorFull';
import Logo from '@/components/Logo';
import { useAppSelector } from '@/hooks/redux';

import Menu, { IMenuDataProps } from './Menu';
import SearchMobile from './SearchMobile';
import * as S from './styles';

const HeaderMobile = () => {
  const isLogged = useAppSelector(state => state.authReducer.auth.isLogged);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSearchVisivle, setIsSearchVisible] = useState(false);
  const [menuContent, setMenuContent] = useState<IMenuDataProps[]>([]);
  const [menuTitle, setMenuTitle] = useState('');

  const profileName = useAppSelector(
    state => state.profileReducer.profile.ContactFirstName
  );
  const pointsBalance = useAppSelector(
    state => state.extractReducer.pointsBalance
  );

  const navigate = useNavigate();

  const menuData = [
    {
      title: 'Troque stix por produtos',
      link: '/catalog'
    },
    {
      title: 'Troque por vale-compras',
      link: '/catalog?filter=?N=ca-2762837d-55f9-46dc-bfa4-d12ca5a0e6c7'
    },
    {
      title: 'PagStix',
      link: '/pagstix'
    },
    {
      title: 'Junte stix',
      link: '/junte-stix'
    },
    {
      title: 'Envie stix',
      link: '/envie-stix'
    },
    {
      title: 'O que é Stix?',
      link: '/sobre-a-stix'
    },
    {
      title: 'Stix para empresas',
      link: '/stix-para-empresas'
    },
    {
      title: 'Central de Ajuda',
      link: '/ajuda'
    }
  ];

  const userMenuData = [
    {
      title: 'Meu Perfil',
      link: '/profile'
    },
    {
      title: 'Minhas Trocas',
      link: '/order-history'
    },
    {
      title: 'Histórico de Atividades',
      link: '/extract'
    },
    {
      title: 'Envie stix',
      link: '/envie-stix'
    },
    {
      title: 'Sair',
      link: '#?'
    }
  ];

  const headerOptionsData = isLogged
    ? [
        {
          iconInfo: 'fas fa-bars',
          title: 'Menu',
          handleClick: () => {
            setMenuContent(menuData);
            setIsMenuVisible(true);
          }
        },
        {
          iconInfo: 'fas fa-search',
          title: 'Pesquisar',
          handleClick: () => {
            setIsSearchVisible(true);
          }
        },
        {
          iconInfo: 'fas fa-receipt',
          title: 'Extrato',
          handleClick: () => {
            navigate('/extract');
          }
        },
        {
          iconInfo: 'fas fa-user-circle',
          title: 'Minha Conta',
          handleClick: () => {
            setMenuTitle(`Olá, ${profileName}`);
            setMenuContent(userMenuData);
            setIsMenuVisible(true);
          }
        }
      ]
    : [
        {
          iconInfo: 'fas fa-bars',
          title: 'Menu',
          handleClick: () => {
            setMenuContent(menuData);
            setIsMenuVisible(true);
          }
        },
        {
          iconInfo: 'fas fa-search',
          title: 'Pesquisar',
          handleClick: () => {
            setIsSearchVisible(true);
          }
        },
        {
          iconInfo: 'fas fa-user-circle',
          title: 'Entrar / Criar conta',
          handleClick: () => {
            navigate('/validation');
          }
        }
      ];

  return (
    <S.Header>
      {isSearchVisivle ? (
        <SearchMobile handleClose={() => setIsSearchVisible(false)} />
      ) : (
        <>
          <S.LogoStixContainer>
            <S.LogoContainer>
              <Logo />
            </S.LogoContainer>
            {isLogged && (
              <S.UserBalanceContainer>
                <S.UserBalanceValue>
                  {new Intl.NumberFormat('pt-BR').format(
                    pointsBalance.Balance || 0
                  )}{' '}
                  stix
                </S.UserBalanceValue>
              </S.UserBalanceContainer>
            )}
          </S.LogoStixContainer>
          <S.ButtonsContainer>
            {headerOptionsData.map(menuItem => (
              <S.Button key={menuItem.title} onClick={menuItem.handleClick}>
                <i className={menuItem.iconInfo}></i>
                <span>{menuItem.title}</span>
              </S.Button>
            ))}
          </S.ButtonsContainer>
          <BarColorFull />
          {isMenuVisible && (
            <Menu
              items={menuContent}
              onClose={() => {
                setIsMenuVisible(false);
                setMenuTitle('');
              }}
              title={menuTitle}
            />
          )}
        </>
      )}
    </S.Header>
  );
};

export default HeaderMobile;
