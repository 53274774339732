import { useLayoutEffect, type ReactNode, forwardRef } from 'react';

import * as S from './styles';

interface IProps {
  children: ReactNode;

  /**
   * Visibilidade do Backdrop
   */
  visible: boolean;
}

const Backdrop = forwardRef<HTMLDivElement, IProps>(function Backdrop(
  { children, visible },
  ref
) {
  useLayoutEffect(() => {
    const selector = document.querySelector('html');
    if (selector && visible) {
      selector.style.overflow = 'hidden';
      return () => {
        selector.style.overflow = 'auto';
      };
    } else if (selector) {
      selector.style.overflow = 'auto';
      return () => {
        selector.style.overflow = 'auto';
      };
    }
  }, [visible]);

  useLayoutEffect(() => {
    const selector = document.querySelector('html');
    if (!visible && selector) selector.style.overflow = 'auto';
  }, [visible]);

  return (
    <S.Background ref={ref} $visible={visible}>
      {children}
    </S.Background>
  );
});

Backdrop.defaultProps = {
  visible: false
};

export default Backdrop;
