/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

import type { Routes } from '@/interface/routes';

const DownloadTheApp = lazy(() => import('./DownloadTheApp'));

const HelpCenter = lazy(() => import('./HelpCenter'));

const AboutStix = lazy(() => import('./AboutStix'));
const PartinerCeA = lazy(() => import('./Partner/CeA'));
const PartnerDrogaraia = lazy(() => import('./Partner/DrogaRaia'));
const PartnerDrogasil = lazy(() => import('./Partner/Drogasil'));
const PartnerExtra = lazy(() => import('./Partner/Extra'));
const PartnerPaoDeAcucar = lazy(() => import('./Partner/PaoDeAcucar'));
const PartnerSodimac = lazy(() => import('./Partner/Sodimac'));
const PartnerShell = lazy(() => import('./Partner/Shell'));
const StixToCompany = lazy(() => import('./StixToCompany'));
const Privacy = lazy(() => import('./Privacy'));
const RegulamentsStix = lazy(() => import('./Regulaments/Stix'));
const PagStix = lazy(() => import('./PagStix'));
const AffiliateDetails = lazy(() => import('./affiliateDetails'));
const JunteStix = lazy(() => import('./JunteStix'));
const Itau = lazy(() => import('./Partner/Itau'));

const routes: Routes[] = [
  {
    path: 'pagstix',
    element: <PagStix />
  },
  {
    path: 'sobre-a-stix',
    element: <AboutStix />
  },
  {
    path: 'stix-para-empresas',
    element: <StixToCompany />
  },
  {
    path: 'ajuda',
    element: <HelpCenter />
  },
  {
    path: 'aviso-de-privacidade',
    element: <Privacy />
  },
  {
    path: 'regulamento-stix',
    element: <RegulamentsStix />
  },
  {
    path: 'cea',
    element: <PartinerCeA />,
    shouldApplyWhiteBackground: true
  },
  {
    path: 'droga-raia',
    element: <PartnerDrogaraia />
  },
  {
    path: 'drogasil',
    element: <PartnerDrogasil />
  },
  {
    path: 'extra',
    element: <PartnerExtra />
  },
  {
    path: 'pao-de-acucar',
    element: <PartnerPaoDeAcucar />
  },
  {
    path: 'sodimac',
    element: <PartnerSodimac />
  },
  {
    path: 'affiliate-details',
    element: <AffiliateDetails />
  },
  {
    path: 'baixe-nosso-app',
    element: <DownloadTheApp />
  },
  {
    path: 'junte-stix',
    element: <JunteStix />
  },
  {
    path: 'iupp-itau',
    element: <Itau />
  },
  {
    path: 'shell',
    element: <PartnerShell />,
    shouldApplyWhiteBackground: true
  }
];

export default routes;
