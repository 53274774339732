export function imageUrlValidator(url: string) {
  const occUrl = process.env.VITE_BASE_URL_IMG;

  if (!url) {
    return '';
  }

  if (url.startsWith('https://')) {
    return url;
  } else {
    return occUrl + url;
  }
}
