import { createSlice } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';
import type { IProfile } from '@/services/types.profile';

export interface ProfileState {
  loading: boolean;
  error: string | undefined;
  profile: IProfile;
}

export const fetchProfile = createAppAsyncThunk(
  'fetchProfile',
  async (_, { getState }) => {
    const cpf = getState().authReducer.auth.memberNumber;
    const req = await api.get(`/stix/members/${cpf}`);

    return req.data;
  }
);

const initialState = {
  loading: false,
  error: undefined,
  profile: {}
} as unknown as ProfileState;

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = undefined;
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.loading = false;
      state.profile = {} as IProfile;
      state.error = action.error.message;
    });
  },
  reducers: {
    revokeProfile(state) {
      state.profile = {} as IProfile;
    }
  }
});
export const { revokeProfile } = profileSlice.actions;

export default profileSlice.reducer;
