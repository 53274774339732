import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const rotateCircle = keyframes`
    0% {
        stroke-dasharray: 1px,200px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100px,200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px,200px;
        stroke-dashoffset: -125px;
    }
`;

export const Container = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;

  span {
    width: 40px;
    height: 40px;
    display: inline-block;
    color: rgb(144, 202, 249);
    -webkit-animation: 1.4s linear 0s infinite normal none running ${rotate};
    animation: 1.4s linear 0s infinite normal none running ${rotate};
  }

  svg {
    color: #5468ff;
  }

  circle {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    -webkit-animation: ${rotateCircle} 1.4s ease-in-out infinite;
    animation: ${rotateCircle} 1.4s ease-in-out infinite;
  }
`;
