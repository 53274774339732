import styled, { keyframes } from 'styled-components';

const containerAnimation = keyframes`
0% {
  transform: translateY(110%);
}
99% {
  transform: translateY(0);
}
`;

export const SearchContainer = styled.div`
  padding: 0 16px;
  padding-bottom: 16px;
  animation: ${containerAnimation} 0.3s ease;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 24px 0;
  width: 100%;
  position: relative;
`;

export const Title = styled.span`
  font-size: 18px;
  color: ${props => props.theme.color.border.secondary};
  text-align: center;
  flex: 1;
`;

export const CloseButton = styled.button`
  background-color: ${props => props.theme.color.primary};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  position: absolute;
  right: 0;
  top: 12px;
`;
