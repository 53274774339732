import styled from 'styled-components';

export const Header = styled.header`
  display: block;
  background: ${props => props.theme.color.white};
  @media ${props => props.theme.devices['max-md']} {
    display: none;
  }
  &:after {
    content: '';
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    opacity: 0;
    transition: ease all 0.3s;
  }
  &.is-sticky {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateZ(0);
    z-index: 200;
    width: 100vw;
  }
  &.is-overlay {
    &:after {
      transition: ease all 0.3s;
      opacity: 1;
      visibility: visible;
    }
  }
  & .Header {
    &-main {
      padding-top: 14px;
      padding-bottom: 20px;
      & .row {
        width: 100%;
      }
    }
    &-bottom {
      z-index: 300;
      height: 48px;
      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-main,
    &-bottom {
      background-color: $white;
      position: relative;
    }
    & .ButtonDownloadApp {
      @include ${props => props.theme.devices['min-lg']} {
        right: 10px;
      }
      @include ${props => props.theme.devices['min-xl']} {
        right: 36px;
      }
    }
  }
  .Logo {
    position: relative;
    display: inline-flex;
    width: 112px;
    top: 4px;

    &-image {
      width: 100%;
    }
  }
`;
