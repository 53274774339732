import { useState } from 'react';

import type { IUpdateItem, cookieOptions } from './types';

const isBrowser = typeof window !== 'undefined';

export function stringifyOptions(options: cookieOptions) {
  return Object.keys(options).reduce((acc, key) => {
    if (key === 'days') {
      return acc;
    } else {
      const keyValue = options[key as keyof cookieOptions];
      if (keyValue === false) {
        return acc;
      } else if (keyValue === true) {
        return `${acc}; ${key}`;
      } else {
        return `${acc}; ${key}=${keyValue}`;
      }
    }
  }, '');
}

export const setCookie = (
  name: string,
  value: string,
  options?: cookieOptions
) => {
  if (!isBrowser) return;

  const optionsWithDefaults = {
    days: 7,
    path: '/',
    ...options
  };

  const expires = new Date(
    Date.now() + optionsWithDefaults.days * 864e5
  ).toUTCString();

  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    expires +
    stringifyOptions(optionsWithDefaults);
};

export const getCookie = (name: string, initialValue = '') => {
  return (
    (isBrowser &&
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '')) ||
    initialValue
  );
};

function useCookie(key: string, initialValue?: string): [string, IUpdateItem] {
  const [item, setItem] = useState(() => {
    return getCookie(key, initialValue);
  });

  const updateItem = (value: string, options?: cookieOptions) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
}

export default useCookie;
