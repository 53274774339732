import styled from 'styled-components';

export const MAGNIFY_SIZE = 160;

export const Container = styled.div`
  @keyframes scaleUp {
    0% {
      transform: scale(0);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  position: absolute;
  display: none;
  z-index: 99;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${props => props.theme.color.white};
  width: ${MAGNIFY_SIZE}px;
  height: ${MAGNIFY_SIZE}px;
  background-size: 800%;
  background-position: center;
  pointer-events: none;
  transform: scale(0);
  animation: scaleUp 200ms ease-in forwards;
`;
