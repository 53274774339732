import { useState } from 'react';
import { Link } from 'react-router-dom';

import BarColorFull from '@/components/BarColorFull';
import Search from '@/components/Search';
import { changeUrlIfIsEmbbed, isEmbbed } from '@/helper';

import Logo from '../../Logo';
import MainMenu from './MainMenu';
import * as S from './styles';
import TopBar from './TopBar';
import UserLogin from './UserLogin';

import './index.scss';

const Header = () => {
  const [isOverlay, setIsOverlay] = useState('');
  const overlay = (params = false) => {
    params !== false ? setIsOverlay('is-overlay') : setIsOverlay('');
  };

  return (
    <S.Header className={`Header is-sticky ${isOverlay}`}>
      <TopBar />
      <div className="Header-main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2">
              <Logo />
            </div>
            <div className="col-6">
              <Search />
            </div>
            <div className="col-3">
              <UserLogin overlay={overlay} />
            </div>
          </div>
        </div>
      </div>
      <div className="Header-bottom">
        <div className="container">
          <div className="Header-bottom__content">
            <MainMenu overlay={overlay} />
            <Link
              to={changeUrlIfIsEmbbed('/baixe-nosso-app')}
              className="ButtonDownloadApp"
              target={isEmbbed() ? '_top' : ''}
            >
              <i className="ButtonDownloadApp-icon fas fa-mobile-alt"></i>
              Baixe nosso App
            </Link>
          </div>
        </div>
      </div>
      <BarColorFull />
    </S.Header>
  );
};

export default Header;
