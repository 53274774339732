export const menuOccHML = [
  {
    level1Title: 'Troque stix',
    href: '',
    level2Group: [
      {
        title: 'Por produtos',
        href: '/catalog',
        level3Group: [
          {
            title: 'Alimentos e bebidas',
            href: '/catalog?filter=?N=3129024589',
            level4Group: [
              {
                title: 'Alimentos',
                href: '/catalog?filter=?N=2372922277&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              },
              {
                title: 'Bebidas',
                href: '/catalog?filter=?N=2853761990&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              }
            ]
          },
          {
            title: 'Bebê e Criança',
            href: '/catalog?filter=?N=899552349',
            level4Group: []
          },
          {
            title: 'Beleza e Dermocosméticos',
            href: '/catalog?filter=?N=757933056',
            level4Group: [
              {
                title: 'Cuidados com a pele',
                href: '/catalog?filter=?N=1726143994&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              },
              {
                title: 'Cabelos',
                href: '/catalog?filter=?N=3402731364&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              }
            ]
          },
          {
            title: 'Cuidados Diários e Higiene',
            href: '/catalog?filter=?N=3832084763',
            level4Group: [
              {
                title: 'Higiene pessoal',
                href: '/catalog?filter=?N=3892670988&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              },
              {
                title: 'Higiene bucal',
                href: '/catalog?filter=?N=2741931578&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              }
            ]
          },
          {
            title: 'Casa e Cozinha',
            href: '/catalog?filter=?N=2666971650',
            level4Group: [
              {
                title: 'Cozinha e mesa',
                href: '/catalog?filter=?N=3817537068&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              }
            ]
          },
          {
            title: 'Nutrição e Suplementos',
            href: '/catalog?filter=?N=3217329156',
            level4Group: [
              {
                title: 'Vitaminas',
                href: '/catalog?filter=?N=2486244283&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              },
              {
                title: 'Suplementos',
                href: '/catalog?filter=?N=354560451&Nr=product.catalogId%3ASTCatalog&Nrpp=12&onlyData=true&suppressResults=false'
              }
            ]
          },
          {
            title: 'Para seu carro',
            href: '/catalog?filter=?N=3324840666',
            level4Group: []
          },
          {
            title: 'Saúde e Bem-estar',
            href: 'catalog?filter=?N=530694326',
            level4Group: []
          }
        ]
      },
      {
        title: 'Por vale compras',
        href: '/catalog?filter=?N=3850372959',
        level3Group: []
      },
      {
        title: 'PagStix',
        href: '/pagstix',
        level3Group: []
      }
    ]
  }
];

export const menuOccPRD = [
  {
    level1Title: 'Troque stix',
    href: '',
    level2Group: [
      {
        title: 'Por produtos',
        href: '/catalog',
        level3Group: [
          {
            title: 'Alimentos e bebidas',
            href: '/catalog?filter=?N=368349086',
            level4Group: [
              {
                title: 'Alimentos',
                href: '/catalog?filter=?N=2539489753'
              },
              {
                title: 'Bebidas',
                href: '/catalog?filter=?N=2173306545'
              },
              {
                title: 'Bebidas alcóolicas',
                href: '/catalog?filter=?N=3900596829'
              }
            ]
          },
          {
            title: 'Bebê e Criança',
            href: '/catalog?filter=?N=2685444373',
            level4Group: [
              {
                title: 'Amamentação',
                href: '/catalog?filter=?N=1017509563'
              },
              {
                title: 'Higiene, banho e cuidados',
                href: '/catalog?filter=?N=1514683973'
              },
              {
                title: 'Fraldas',
                href: '/catalog?filter=?N=216387218'
              }
            ]
          },
          {
            title: 'Beleza e Dermocosméticos',
            href: '/catalog?filter=?N=1742305236',
            level4Group: [
              {
                title: 'Cuidados com a pele',
                href: '/catalog?filter=?N=2436685243'
              },
              {
                title: 'Cuidados com o corpo',
                href: '/catalog?filter=?N=1283794330'
              },
              {
                title: 'Cabelos',
                href: '/catalog?filter=?N=3461114522'
              }
            ]
          },
          {
            title: 'Cuidados Diários e Higiene',
            href: '/catalog?filter=?N=3400279914',
            level4Group: [
              {
                title: 'Higiene pessoal',
                href: '/catalog?filter=?N=49146179'
              },
              {
                title: 'Desodorantes',
                href: '/catalog?filter=?N=2509673614'
              },
              {
                title: 'Depilação',
                href: '/catalog?filter=?N=2401312280'
              },
              {
                title: 'Repelentes',
                href: '/catalog?filter=?N=1486920058'
              },
              {
                title: 'Higiene bucal',
                href: '/catalog?filter=?N=49146179'
              }
            ]
          },
          {
            title: 'Casa e Cozinha',
            href: '/catalog?filter=?N=978042253',
            level4Group: []
          },
          {
            title: 'Nutrição e Suplementos',
            href: '/catalog?filter=?N=2353849407',
            level4Group: [
              {
                title: 'Vitaminas',
                href: '/catalog?filter=?N=2373965277'
              },
              {
                title: 'Suplementos',
                href: '/catalog?filter=?N=2018808964'
              },
              {
                title: 'Proteínas',
                href: '/catalog?filter=?N=2600596817'
              }
            ]
          },
          {
            title: 'Para seu carro',
            href: '/catalog?filter=?N=2831358195',
            level4Group: []
          },
          {
            title: 'Saúde e Bem-estar',
            href: 'catalog?filter=?N=152866179',
            level4Group: [
              {
                title: 'Pele, cabelo e unhas',
                href: '/catalog?filter=?N=2480476218'
              }
            ]
          }
        ]
      },
      {
        title: 'Por vale compras',
        href: '/catalog?filter=?N=851306073',
        level3Group: []
      },
      {
        title: 'PagStix',
        href: '/pagstix',
        level3Group: []
      }
    ]
  }
];

export const menuTegra = [
  {
    level1Title: 'Troque stix',
    href: '',
    level2Group: [
      {
        title: 'Por produtos',
        href: '/catalog',
        level3Group: [
          {
            title: 'Alimentos e bebidas',
            href: '/catalog?filter=?N=ca-8b2ae092-0767-404b-9c5c-21cd444b06ef',
            level4Group: [
              {
                title: 'Alimentos',
                href: '/catalog?filter=?N=su-6d852a14-30df-4e04-8b59-c7d4e604a55a'
              },
              {
                title: 'Bebidas',
                href: '/catalog?filter=?N=su-9252bbc0-5b42-4d0f-b576-360d01a92a97'
              },
              {
                title: 'Bebidas alcóolicas',
                href: '/catalog?filter=?N=su-27f5635e-9789-4ae1-90f4-5b4fb0e345bf'
              }
            ]
          },
          {
            title: 'Bebê e Criança',
            href: '/catalog?filter=?N=ca-72a69fa2-09da-4c7f-875b-bec014616ba0',
            level4Group: [
              {
                title: 'Amamentação',
                href: '/catalog?filter=?N=su-0d296b91-efa3-4aef-adc9-4b4ead7158b7'
              },
              {
                title: 'Higiene, banho e cuidados',
                href: '/catalog?filter=?N=su-5a6401ac-065c-4a33-ba0b-876f4df4e6fc'
              },
              {
                title: 'Fraldas',
                href: '/catalog?filter=?N=su-c5fd40f5-4349-4e72-a38a-0e649daea62c'
              }
            ]
          },
          {
            title: 'Beleza e Dermocosméticos',
            href: '/catalog?filter=?N=ca-4989c4da-7f64-484a-995b-82ca10b99bfc',
            level4Group: [
              {
                title: 'Cuidados com a pele',
                href: '/catalog?filter=?N=su-d41bdf26-46a6-4274-a5c1-0b2201812623'
              },
              {
                title: 'Cuidados com o corpo',
                href: '/catalog?filter=?N=su-c48021f7-7fea-4894-89e7-82ec8ffbd6a4'
              },
              {
                title: 'Maquiagem',
                href: '/catalog?filter=?N=su-f93917d1-d6f3-44fb-a115-3d446d0bd897'
              },
              {
                title: 'Cabelos',
                href: '/catalog?filter=?N=su-4aaa4b78-5460-4751-883c-3a6f28d6d9b4'
              }
            ]
          },
          {
            title: 'Cuidados Diários e Higiene',
            href: '/catalog?filter=?N=ca-10e0e727-ae57-49be-9ce0-3a1944a6807a',
            level4Group: [
              {
                title: 'Higiene pessoal',
                href: '/catalog?filter=?N=su-9dd5d72c-d502-411b-909d-ec669992d633'
              },
              {
                title: 'Desodorantes',
                href: '/catalog?filter=?N=su-31420ff2-5406-4aa5-a327-b26ef3e074c1'
              },
              {
                title: 'Depilação',
                href: '/catalog?filter=?N=su-6b5d1df8-cebc-445c-8720-0e37dc47af36'
              },
              {
                title: 'Repelentes',
                href: '/catalog?filter=?N=su-aefdfe5c-3d7f-45b2-addb-9e0e0303f356'
              },
              {
                title: 'Higiene bucal',
                href: '/catalog?filter=?N=su-be5df507-343a-4a4b-bff3-cfc39851737e'
              }
            ]
          },
          {
            title: 'Casa e Cozinha',
            href: '/catalog?filter=?N=ca-2f835ebb-7c55-44b6-99b4-154689f28b87',
            level4Group: [
              {
                title: 'Cozinha e mesa',
                href: '/catalog?filter=?N=su-969c8178-073c-403d-b223-ed268cb9c06c'
              },
              {
                title: 'Banho',
                href: '/catalog?filter=?N=su-484e5913-0c3b-48a5-9a3e-f8b034ddd14b'
              }
            ]
          },
          {
            title: 'Nutrição e Suplementos',
            href: '/catalog?filter=?N=ca-77b2f8f6-05f7-481e-a1dd-4d4593091c59',
            level4Group: [
              {
                title: 'Vitaminas',
                href: '/catalog?filter=?N=su-110f1def-67d2-4905-95f3-99ec7213ae4b'
              },
              {
                title: 'Suplementos',
                href: '/catalog?filter=?N=su-b7b925d2-2431-41c0-9326-eb705c037e62'
              },
              {
                title: 'Proteínas',
                href: '/catalog?filter=?N=su-db76edff-28df-41da-a05a-0f9edc7a50d4'
              }
            ]
          },
          {
            title: 'Para seu carro',
            href: '/catalog?filter=?N=ca-a6637cd4-9026-4667-a885-716a6940cc73',
            level4Group: [
              {
                title: 'Combustível',
                href: '/catalog?filter=?N=su-96e5b9bd-6e4d-4045-b856-3f361997d0ed'
              }
            ]
          },
          {
            title: 'Saúde e Bem-estar',
            href: 'catalog?filter=?N=ca-75b6a212-c119-40c5-b69c-014d32ef01d7',
            level4Group: [
              {
                title: 'Pele, cabelo e unhas',
                href: 'catalog?filter=?N=su-82aa554e-275e-45ba-a6f5-b20351b889eb'
              }
            ]
          }
        ]
      },
      {
        title: 'Por vale compras',
        href: '/catalog?filter=?N=ca-2762837d-55f9-46dc-bfa4-d12ca5a0e6c7',
        level3Group: []
      },
      {
        title: 'PagStix',
        href: '/pagstix',
        level3Group: []
      }
    ]
  }
];
