import { type ReactNode, type HTMLAttributes, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTheme } from 'styled-components';

import CookieBar from '@/components/CookieBar';
import { useEmbed } from '@/hooks/useEmbed';
import useMediaQuery from '@/hooks/useMediaQuery';

import Footer from './Footer';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import * as S from './styles';
interface IProps extends HTMLAttributes<HTMLOrSVGElement> {
  shouldApplyWhiteBackground?: boolean;
}

function Layout({ children, shouldApplyWhiteBackground, ...props }: IProps) {
  const { pathname } = useLocation();
  const { embed } = useEmbed();
  const theme = useTheme();
  const media = useMediaQuery(theme.devices['max-md']);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (embed) {
    document.body.style.paddingTop = '0px';
    return (
      <>
        <S.Main
          $shouldApplyWhiteBackground={shouldApplyWhiteBackground}
          {...props}
        >
          <S.PageContainer>
            <S.ContentContainer>{children as ReactNode}</S.ContentContainer>
          </S.PageContainer>
        </S.Main>
        <Footer />
        <CookieBar />
      </>
    );
  }
  document.body.style.removeProperty('padding-top');

  return (
    <>
      {media ? <HeaderMobile /> : <HeaderDesktop />}
      <S.Main
        $shouldApplyWhiteBackground={shouldApplyWhiteBackground}
        {...props}
      >
        <S.PageContainer>
          <S.ContentContainer>{children as ReactNode}</S.ContentContainer>
        </S.PageContainer>
      </S.Main>
      <Footer />
      <CookieBar />
    </>
  );
}

export default Layout;
