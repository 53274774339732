import * as S from './styles';

function Copyright() {
  return (
    <S.Container>
      <p>Stix fidelidade e inteligência s.a </p>
      <p>CNPJ nº 31.688.927/0001-90</p>
    </S.Container>
  );
}

export default Copyright;
