import { createSlice } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';
import { IOrderHistory } from '@/services/types.orderHistory';

export const fetchOrderHistory = createAppAsyncThunk(
  'fetchOrderHistory',
  async (_, { getState }) => {
    const memberNumber = getState().authReducer.auth.memberNumber;
    const oldOrderHistory = getState().orderHistoryReducer;
    if (oldOrderHistory.hasMore) {
      const req = await api.get<IOrderHistory>(
        `stix/members/${memberNumber}/voucher?offset=${
          oldOrderHistory.offset + 20
        }&limit=20`
      );
      return {
        ...req.data,
        items: [...oldOrderHistory.items, ...req.data.items]
      };
    }
    return oldOrderHistory;
  }
);

const initialState = {
  loading: false,
  loadingMore: false,
  items: [] as IOrderHistory['items'],
  recentList: [] as IOrderHistory['items'],
  concludedList: [] as IOrderHistory['items'],
  offset: -20,
  hasMore: true,
  error: '' as string | undefined
};

const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchOrderHistory.pending, state => {
      if (state.offset < 0) {
        state.loading = true;
      } else {
        state.loadingMore = true;
      }
    });
    builder.addCase(fetchOrderHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.loadingMore = false;
      state.recentList = action.payload.items.filter(
        item => item?.StatusCode === 'ORA_VOUC_STATUS_AVLBL'
      );
      state.concludedList = action.payload.items.filter(
        item => item?.StatusCode !== 'ORA_VOUC_STATUS_AVLBL'
      );
      state.hasMore = action.payload.hasMore;
      state.items = action.payload.items;
      state.offset = action.payload.offset;
      state.error = '';
    });
    builder.addCase(fetchOrderHistory.rejected, (state, action) => {
      state.loading = false;
      state.loadingMore = false;
      state.recentList = [];
      state.concludedList = [];
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default orderHistorySlice.reducer;
