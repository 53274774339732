/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

import type { Routes } from '@/interface/routes';

const ProductList = lazy(() => import('./ProductList'));
const ProductDetails = lazy(() => import('./ProductDetails'));
const Confirmation = lazy(() => import('./Confirmation'));
const ConfirmationSuccess = lazy(() => import('./ConfirmationSuccess'));
const LimitChangeError = lazy(() => import('./LimitChangeError'));

const routes: Routes[] = [
  {
    path: 'catalog',
    element: <ProductList />
  },
  {
    path: 'catalog/product/:slug',
    element: <ProductDetails />
  },
  {
    path: 'catalog/product/confirmation/:slug',
    element: <Confirmation />
  },
  {
    path: 'catalog/product/confirmation/:slug/:providerId',
    element: <Confirmation />
  },
  {
    path: 'catalog/product/confirmation/success/:slug/:providerId/:orderId',
    element: <ConfirmationSuccess />
  },
  {
    path: 'limit-change-error/:slug',
    element: <LimitChangeError />
  }
];

export default routes;
