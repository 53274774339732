import styled, { createGlobalStyle } from 'styled-components';

interface ModalProps {
  $visible: boolean;
}

export const Content = styled.div<ModalProps>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  min-width: 240px;
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  animation: ${({ $visible }) => ($visible ? 'fadeIn 1s' : 'fadeOut 1s')};
  background-color: ${props => props.theme.color.white};
  padding: 32px;
  border-radius: 16px;
  max-width: 90%;
  max-height: 90%;
  border: 2px solid ${props => props.theme.color.grey2};
  position: relative;
  overflow: auto;
`;

export const IconClose = styled.i.attrs(() => ({
  className: 'fad fa-times-circle'
}))`
  --fa-primary-color: #16074a;
  --fa-secondary-color: #20b4fe;
  --fa-secondary-opacity: 16074;
  position: absolute;
  right: 0px;
  top: -5px;
  font-size: 32px;
  cursor: pointer;
`;

export const HeaderRow = styled.div`
  padding-bottom: 32px;
  position: relative;
  h3 {
    color: ${props => props.theme.color.border.secondary};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const ScrollDisabler = createGlobalStyle`
  html, body {
    overflow-y: hidden !important;
  }
`;
